<div class="form-group radio-group" [formGroup]="group" [hidden]="this.isHidden">
    <label class="question-label radio-question-label" [attr.for]="identifier" *ngIf="label">{{ label }}</label>
    <div [attr.id]="identifier" class="row" ngbRadioGroup>
      <div class="col-6" *ngFor="let option of options">
        <label ngbButtonLabel class="radio-label" [ngClass]="this.label ? '' : 'no-question'">
          <input formControlName="value" type="radio" [value]="option.key" (change)="valueChanged()" [disabled]="disabled || !isOptionEnabled(option.value)">
          {{ option.value }}
        </label>
      </div>
    </div>
  </div>
  