import { BrowserModule } from '@angular/platform-browser';
import {
  NgModule,
  LOCALE_ID,
  ErrorHandler,
  APP_INITIALIZER,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { registerLocaleData, CurrencyPipe, DatePipe } from '@angular/common';
import localeENGB from '@angular/common/locales/en-GB';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType,
} from '@azure/msal-browser';
import {
  MsalGuard,
  MsalInterceptor,
  MsalBroadcastService,
  MsalInterceptorConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuardConfiguration,
  MsalRedirectComponent,
} from '@azure/msal-angular';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastNoAnimationModule } from 'ngx-toastr';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { UserInputComponent } from './user-input/user-input.component';
import { CustomErrorHandler } from './custom-error-handler';
import { ValuationComponent } from './valuation/valuation.component';
import { VehicleCheckComponent } from './vehicle-check-hpi/vehicle-check-hpi.component';
import { VehicleMarkerComponent } from './vehicle-check-hpi/markers/vehicle-marker.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { ConditionRecordsComponent } from './vehicle-check-hpi/condition-records/condition-records.component';
import { ErrorComponent } from './error/error.component';
import { NumberInputComponent } from './inputs/number-input/number-input.component';
import { DamageAreaComponent } from './damage/damage-area/damage-area.component';
import { DamageTotalComponent } from './damage/damage-total/damage-total.component';
import { DamageDirective } from './damage/damage.directive';
import { DamageCostComponent } from './damage/damage-cost/damage-cost.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { TextInputComponent } from './inputs/text-input/text-input.component';
import { MileageComponent } from './mileage/mileage.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceHistoryWarrantyCheckComponent } from './service-history/service-history-warranty-check/service-history-warranty-check.component';
import { BaseInputComponent } from './inputs/base-input/base-input.component';
import { ListInputComponent } from './inputs/list-input/list-input.component';
import { MultiSelectInputComponent } from './inputs/check-input/multi-select-input.component';
import { RecordSourceDescriptionComponent } from './service-history/record-source-description/record-source-description.component';
import { DisplayServiceHistoryStateComponent } from './service-history/display-service-history-state/display-service-history-state.component';
import { DropdownInputComponent } from './inputs/dropdown-input/dropdown-input.component';
import { DateInputComponent } from './inputs/date-input/date-input.component';
import { NgxMaskModule } from 'ngx-mask';
import { OfferDisplayComponent } from './offer-display/offer-display.component';
import { BaseComponentDirective } from './base/base.component';
import { PostcodeLookupInputComponent } from './inputs/postcode-lookup-input/postcode-lookup-input.component';
import { SearchableDropdownInputComponent } from './inputs/searchable-dropdown-input/searchable-dropdown-input.component';
import { ModalInputComponent } from './modal-input/modal-input.component';
import { BankVerificationComponent } from './bank-verification/bank-verification.component';
import { CongratulationsComponent } from './congratulations/congratulations.component';
import { ImageInputComponent } from './images/image-input/image-input.component';
import { PurchaseReviewComponent } from './purchase/purchase-review/purchase-review.component';
import { PurchaseSummaryComponent } from './purchase/purchase-summary/purchase-summary.component';
import { OutstandingFinanceComponent } from './outstanding-finance/outstanding-finance.component';
import { AppInitService } from '../environments/environmentLoader';
import { InvoiceComponent } from './invoice/invoice.component';
import { SignatureInputComponent } from './signature-input/signature-input.component';
import { CustomerGuaranteesComponent } from './customer-guarantees/customer-guarantees.component';
import { NotPurchasedReasonsComponent } from './not-purchased-reasons/not-purchased-reasons.component';
import { VatCheckInputComponent } from './inputs/vat-check-input/vat-check-input.component';
import { AuthService } from './core/auth-service';
import { DiaryComponent } from './diary/diary.component';
import { DayViewComponent } from './diary/day-view/day-view.component';
import { TimeMarkerComponent } from './diary/time-marker/time-marker.component';
import { PurchaseMenuComponent } from './purchase-menu/purchase-menu.component';
import { msalConfig, loginRequest, protectedResources } from './auth-config';
import { RoleGuardService } from './role-guard.service';
import { DayViewAppointmentsComponent } from './diary/day-view/day-view-appointments.component';
import { WeekViewComponent } from './diary/week-view/week-view.component';
import { WalkInComponent } from './walk-in/walk-in.component';
import { AppointmentDetailsComponent } from './appointment-details/appointment-details.component';
import { QuickQuoteComponent } from './quick-quote/quick-quote.component';
import { LookupErrorComponent } from './search-vrm/lookup-error/lookup-error.component';
import { VehicleDetailsComponent } from './search-vrm/vehicle-details/vehicle-details.component';
import { SearchVrmComponent } from './search-vrm/search-vrm.component';
import { VehicleImageComponent } from './search-vrm/vehicle-image/vehicle-image.component';
import { PreviousValuationsLookupComponent } from './search-vrm/previous-valuations-lookup/previous-valuations-lookup.component';
import { RegistrationPlateComponent } from './search-vrm/registration-plate/registration-plate.component';
import { MultipleDerivativesComponent } from './search-vrm/multiple-derivatives/multiple-derivatives.component';
import { ValuationQuestionsComponent } from './walk-in/valuation-questions/valuation-questions.component';
import { ManualLookupComponent } from './search-vrm/manual-lookup/manual-lookup.component';
import { CancellationPurchaseComponent } from './cancellation-purchase/cancellation-purchase.component';
import { CancellationReasonsComponent } from './cancellation-reasons/cancellation-reasons.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ConfirmDialogListComponent } from './confirm-dialog/confirm-dialog-list.component';
import { BookAppointmentComponent } from './book-appointment/book-appointment.component';
import { DiaryViewTypes } from './enums/diary-view-types';
import { LoadingWbacAnimationComponent } from './loading-wbac-animation/loading-wbac-animation.component';
import { TimeSlotsComponent } from './book-appointment/views/time-slots/time-slots.component';
import { AppointmentSummaryComponent } from './book-appointment/views/appointment-summary/appointment-summary.component';
import { AppointmentSlotsComponent } from './book-appointment/views/appointment-slots/appointment-slots.component';
import { PostcodeLookupComponent } from './postcode-lookup/postcode-lookup.component';
import { PurchaseQuestionsComponent } from './walk-in/purchase-questions/purchase-questions.component';
import { DashMileageModalComponent } from './dash-mileage-modal/dash-mileage-modal.component';
import { ManualEntryComponent } from './inputs/postcode-lookup-input/manual-entry/manual-entry.component';
import { AppointmentValuationDetailsComponent } from './appointment-valuation-details/appointment-valuation-details.component';
import { MotHistoryComponent } from './mileage/mot-history/mot-history.component';
import { PlateDetailsComponent } from './vehicle-check-hpi/plate-details/plate-details.component';
import { StolenRecordsComponent } from './vehicle-check-hpi/stolen-records/stolen-records.component';
import { BlankDatePipe } from './helpers/blank-date.pipe';
import { FinanceRecordsComponent } from './vehicle-check-hpi/finance-records/finance-records.component';
import { StockComponent } from './stock/stock.component';
import { DailyStockComponent } from './stock/daily-stock/daily-stock.component';
import { StockInventoryComponent } from './stock/stock-inventory/stock-inventory.component';
import { ProgressBarComponent } from './components/progress-bar.component';
import { ServiceIntervalCheckComponent } from './service-history/service-interval-check/service-interval-check.component';
import { InspectionRecordsComponent } from './vehicle-check-hpi/inspection-records/inspection-records.component';
import { DiaryViewErrorComponent } from './diary/diary-view-error/diary-view-error/diary-view-error.component';
import { TaskListComponent } from './task-list/task-list.component';
import { RadioInputComponent } from './inputs/radio-input/radio-input.component';
import { RequiredToBuyComponent } from './required-to-buy/required-to-buy.component';
import { ModalSelectComponent } from './modal-select/modal-select.component';
import { AuthorizationRequestComponent } from './authorization/authorization-request.component';
import { PagerComponent } from './pager/pager.component';
import { PopupSelectComponent } from './inputs/popup-select-input/popup-select-input.component';
import { D2FormComponent } from './d2-form/d2-form.component';
import { TaskListUserComponent } from './task-list/task-list-user/task-list-user.component';
import { TaskListAuthComponent } from './task-list/task-list-auth/task-list-auth.component';
import { AppointmentDetailsAuthComponent } from './appointment-details-auth/appointment-details-auth.component';
import { MileageDisplayComponent } from './mileage-display/mileage-display.component';
import { AppraisalReportComponent } from './damage/appraisal-report/appraisal-report.component';
import { PaymentOptionsComponent } from './payment-options/payment-options.component';
import { HpiModalComponent } from './hpi-modal/hpi-modal.component';
import { VideoInputComponent } from './images/video-input/video-input.component';
import { UpjarComponent } from './upjar/upjar.component';
import { HpiRetryComponent } from './vehicle-check-hpi/hpi-retry/hpi-retry.component';
import { VrmInputModalComponent } from './search-vrm/vrm-input-modal/vrm-input-modal.component';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { AuthTaskDetailsComponent } from './task-list/auth-task-details/auth-task-details.component';
import { TaskListRetrieveComponent } from './task-list/task-list-retrieve/task-list-retrieve.component';
import { AltVrmComponent } from './alt-vrm/alt-vrm.component';
import { DiaryVrmSearchComponent } from './diary/diary-vrm-search/diary-vrm-search.component';
import { DisplayAnswerComponent } from './appointment-details-auth/display-answer/display-answer.component';
import { QuizComponent } from './quiz/quiz.component';
import { QuizQuestionComponent } from './quiz/quiz-question.component';
import { QuizGuard } from './quiz/guard/quiz.guard';
import { VrmConfirmationModalComponent } from './vrm-confirmation-modal/vrm-confirmation-modal.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { SalvagedRecordsComponent } from './vehicle-check-hpi/salvaged-records/salvaged-records.component';
import { BankVerificationServiceComponent } from './bank-verification/bank-verification-service/bank-verification-service.component';
import { OptionalExtrasComponent } from './optional-extras/optional-extras.component';
import { OptionalExtrasDisplayComponent } from './optional-extras/optional-extras-display/optional-extras-display.component';
import { QuestionColumnComponent } from './user-input/question-column/question-column.component';
import { VinInputComponent } from './inputs/vin-input/vin-input.component';
import { AutoTabDirective } from './inputs/directives/auto-tab.directive';

const MY_DATE_FORMAT = {
  parse: {
    dateInput: 'DD/MM/YYYY', // this is how your date will be parsed from Input
  },
  display: {
    dateInput: 'DD/MM/YYYY', // this is how your date will get displayed on the Input
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

export function initializeApp1(appInitService: AppInitService) {
  return (): Promise<void> => {
    if (environment.production) {
      return appInitService.initProd();
    } else {
      return appInitService.initDebug();
    }
  };
}
registerLocaleData(localeENGB);

/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

/**
 * MSAL Angular will automatically retrieve tokens for resources
 * added to protectedResourceMap. For more info, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/initialization.md#get-tokens-for-web-api-calls
 */
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string> | null>();

  protectedResourceMap.set(
    protectedResources.privateApiDesktop.endpoint,
    protectedResources.privateApiDesktop.scopes
  );
  protectedResourceMap.set(
    protectedResources.privateApiAzure.endpoint,
    protectedResources.privateApiAzure.scopes
  );
  protectedResourceMap.set(
    protectedResources.privateApiTablet.endpoint,
    protectedResources.privateApiTablet.scopes
  );
  protectedResourceMap.set(
    protectedResources.frontDoorPrivateApiAzure.endpoint,
    protectedResources.frontDoorPrivateApiAzure.scopes
  );

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: loginRequest,
  };
}

@NgModule({
  declarations: [
    AppComponent,
    UserInputComponent,
    ValuationComponent,
    VehicleCheckComponent,
    VehicleMarkerComponent,
    PlateDetailsComponent,
    StolenRecordsComponent,
    FinanceRecordsComponent,
    InspectionRecordsComponent,
    ConfirmationComponent,
    NumberInputComponent,
    ErrorComponent,
    DamageAreaComponent,
    DamageTotalComponent,
    DamageDirective,
    DamageCostComponent,
    SideMenuComponent,
    TextInputComponent,
    ConditionRecordsComponent,
    MileageComponent,
    BaseInputComponent,
    ListInputComponent,
    MultiSelectInputComponent,
    ServiceHistoryWarrantyCheckComponent,
    ServiceIntervalCheckComponent,
    RecordSourceDescriptionComponent,
    DisplayServiceHistoryStateComponent,
    DropdownInputComponent,
    OfferDisplayComponent,
    DateInputComponent,
    BaseComponentDirective,
    PostcodeLookupInputComponent,
    SearchableDropdownInputComponent,
    ModalInputComponent,
    BankVerificationComponent,
    CongratulationsComponent,
    ImageInputComponent,
    PurchaseReviewComponent,
    PurchaseSummaryComponent,
    OutstandingFinanceComponent,
    InvoiceComponent,
    SignatureInputComponent,
    CustomerGuaranteesComponent,
    NotPurchasedReasonsComponent,
    VatCheckInputComponent,
    DiaryComponent,
    AppointmentDetailsComponent,
    DayViewComponent,
    TimeMarkerComponent,
    PurchaseMenuComponent,
    WalkInComponent,
    DayViewAppointmentsComponent,
    WeekViewComponent,
    QuickQuoteComponent,
    LookupErrorComponent,
    VehicleDetailsComponent,
    SearchVrmComponent,
    VehicleImageComponent,
    PreviousValuationsLookupComponent,
    RegistrationPlateComponent,
    MultipleDerivativesComponent,
    ValuationQuestionsComponent,
    BookAppointmentComponent,
    ManualLookupComponent,
    ValuationQuestionsComponent,
    CancellationPurchaseComponent,
    CancellationReasonsComponent,
    ConfirmDialogComponent,
    LoadingWbacAnimationComponent,
    ConfirmDialogListComponent,
    TimeSlotsComponent,
    AppointmentSummaryComponent,
    AppointmentSlotsComponent,
    PostcodeLookupComponent,
    PurchaseQuestionsComponent,
    DashMileageModalComponent,
    AppointmentValuationDetailsComponent,
    ManualEntryComponent,
    MotHistoryComponent,
    BlankDatePipe,
    StockComponent,
    DailyStockComponent,
    StockInventoryComponent,
    MotHistoryComponent,
    DiaryViewErrorComponent,
    ProgressBarComponent,
    TaskListComponent,
    RadioInputComponent,
    ModalSelectComponent,
    TaskListUserComponent,
    TaskListAuthComponent,
    TaskListRetrieveComponent,
    AppointmentDetailsAuthComponent,
    RequiredToBuyComponent,
    AuthorizationRequestComponent,
    RequiredToBuyComponent,
    PagerComponent,
    PopupSelectComponent,
    D2FormComponent,
    AppraisalReportComponent,
    PaymentOptionsComponent,
    VideoInputComponent,
    MileageDisplayComponent,
    HpiRetryComponent,
    UpjarComponent,
    HpiModalComponent,
    VrmInputModalComponent,
    AuthTaskDetailsComponent,
    AltVrmComponent,
    DiaryVrmSearchComponent,
    DisplayAnswerComponent,
    QuizComponent,
    QuizQuestionComponent,
    VrmConfirmationModalComponent,
    SalvagedRecordsComponent,
    BankVerificationServiceComponent,
    OptionalExtrasComponent,
    OptionalExtrasDisplayComponent,
    QuestionColumnComponent,
    VinInputComponent,
    AutoTabDirective
  ],
  imports: [
    MatDatepickerModule, 
    MatInputModule, 
    MatNativeDateModule, 
    MatFormFieldModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgScrollbarModule,
    RouterModule.forRoot(
      [
        {
          path: '',
          component: DiaryComponent,
          pathMatch: 'full',
          canActivate: [MsalGuard, QuizGuard],
        },
        {
          path: 'week',
          component: DiaryComponent,
          pathMatch: 'full',
          canActivate: [MsalGuard, QuizGuard],
          data: { view: DiaryViewTypes.Week },
        },
        {
          path: 'purchase/:id',
          component: UserInputComponent,
          pathMatch: 'full',
          canActivate: [MsalGuard],
        },
        {
          path: 'postPurchase/images/:id',
          component: UserInputComponent,
          pathMatch: 'full',
          canActivate: [MsalGuard],
          data: { postPurchase: true },
        },
        {
          path: 'review',
          component: PurchaseReviewComponent,
          pathMatch: 'full',
          data: {
            expectedRole: [AuthService.viewReviewPurchases],
          },
          canActivate: [MsalGuard, RoleGuardService],
        },
        {
          path: 'quiz',
          component: QuizComponent,
          pathMatch: 'full',
          canActivate: [MsalGuard],
        },
        {
          path: 'stock',
          component: StockComponent,
          pathMatch: 'full',
          canActivate: [MsalGuard, QuizGuard],
        },
        {
          path: 'summary/:id',
          component: PurchaseSummaryComponent,
          pathMatch: 'full',
          data: {
            expectedRole: [AuthService.viewReviewPurchases],
          },
          canActivate: [MsalGuard, RoleGuardService],
        },
        {
          path: 'diary',
          component: DiaryComponent,
          pathMatch: 'full',
          canActivate: [MsalGuard, QuizGuard]
        },
        {
          path: 'tasks/:view',
          component: TaskListComponent,
          pathMatch: 'full',
          canActivate: [MsalGuard, QuizGuard],
        },
        {
          path: 'tasks',
          component: TaskListComponent,
          pathMatch: 'full',
          canActivate: [MsalGuard, QuizGuard],
        },
        {
          path: 'upjar',
          component: UpjarComponent,
          pathMatch: 'full',
          data: {
            expectedRole: [AuthService.viewUpjar],
          },
          canActivate: [MsalGuard],
        },
        {
          path: 'error',
          component: ErrorComponent,
          pathMatch: 'full',
        },
      ],
      { onSameUrlNavigation: 'reload' }
    ),
    ToastNoAnimationModule.forRoot(),
    BrowserAnimationsModule,
    NgxMaskModule.forRoot(),
    CurrencyMaskModule
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMAT },
    { provide: LOCALE_ID, useValue: 'en-GB' },
    { provide: ErrorHandler, useClass: CustomErrorHandler },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp1,
      multi: true,
      deps: [AppInitService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    CurrencyPipe,
    DatePipe,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule { }
