import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { EMPTY, Subject } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { ToDoService } from '../../services/to-do.service';
import { NotificationService } from '../../services/notification.service';
import { ValuationService } from '../../services/valuation.service';
import { WalkInLookupData } from '../models/walk-in-lookup-data.model';
import { CustomValidators } from '../../custom-validators/custom-validators';
import { VehicleListValue } from '../models/vehicle-list-value.model';
import { ImageService } from '../../services/image.service';
import { SharedService } from '../../core/shared.service';
import { VehicleCheckService } from '../../services/vehicle-check.service';
import { VehicleLookupStatusTypes } from '../../enums/vehicle-lookup-status-types';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HpiRetryComponent } from '../../vehicle-check-hpi/hpi-retry/hpi-retry.component';
import { LookupService } from '../../services/lookup.service';
import { ModalHelper } from '../../helpers/modal-helper';
import { PathFinderService } from '../../services/path-finder.service';
import { KeyValuePair } from '../../models/key-value-pair.model';

@Component({
  selector: 'app-purchase-questions',
  templateUrl: './purchase-questions.component.html',
  styleUrls: ['./purchase-questions.component.scss'],
})
export class PurchaseQuestionsComponent implements OnInit, OnDestroy {
  valuationQuestionsForm: UntypedFormGroup;
  loading = false;
  images = [
    {
      stampCode: 'DB',
      documentName: 'Dashboard',
    },
  ];

  @Input() vrm: string;
  @Input() altVrm: string;
  @Input() quoteStateId: number;
  @Input() bookId: number;
  @Input() quoteId: number;
  @Input() quoteGuid: string;
  @Input() vehicle: VehicleListValue;
  @Input() requireCustomerName: string;
  @Input() isBookedVrm: boolean;
  @Input() userData: WalkInLookupData;
  @Output() dismissModal = new EventEmitter<boolean>();
  @Output() requiresManualValuation = new EventEmitter<boolean>();
  @Output() highValueRequiresAppointment = new EventEmitter<WalkInLookupData>();
  @Output() gotPurchaseSettings = new EventEmitter<Array<KeyValuePair>>();
  isImageAdded: boolean;
  saveNotifier: Subject<number> = new Subject();
  destroy$ = new Subject();
  imageQualitySetting: number;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private notifications: NotificationService,
    private valuationService: ValuationService,
    private todoService: ToDoService,
    private imageService: ImageService,
    private sharedService: SharedService,
    private vehicleCheckService: VehicleCheckService,
    private modalService: NgbModal,
    private lookupService: LookupService,
    private pathFinder: PathFinderService
  ) {}

  ngOnInit(): void {
    let mileage = '';
    let name = '';
    let email = '';
    let phone = '';
    if (this.userData) {
      mileage = this.userData.mileage.toString();
      name = this.userData.customerName;
      email = this.userData.emailAddress;
      phone = this.userData.telephoneNumber;
    }

    this.valuationQuestionsForm = this.formBuilder.group({
      mileage: [mileage, { validators: Validators.required, asyncValidators: [CustomValidators.validateNumber(1, 999999)]}],
      customerName: [name, {validators: Validators.compose([this.conditionallyRequiredValidator(() => this.requireCustomerName),
            Validators.maxLength(50)]), asyncValidators: [CustomValidators.validateRegex(CustomValidators.customerNameRegex)]}],
      emailAddress: [email, { validators: Validators.compose([this.conditionallyRequiredValidator(() => this.requireCustomerName),
            Validators.maxLength(256)]), asyncValidators: [CustomValidators.validateRegex(CustomValidators.emailAddressRegex)] }],
      telephone: [phone, { validators: Validators.compose([this.conditionallyRequiredValidator(() => this.requireCustomerName),
            Validators.maxLength(64)]), asyncValidators: [CustomValidators.validateRegex(CustomValidators.telephoneRegex)] }],
    });

    this.imageService.imageAdded.subscribe((value) => {
      this.isImageAdded = value;
    });

    this.pathFinder.getPurchaseSettings$().pipe(    
      catchError(err => {
        this.notifications.dangerToast('Unable to retrieve price promise.', err);
        return EMPTY;
      }), 
      take(1)
    ).subscribe();

  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  conditionallyRequiredValidator(predicate) {
    return (formControl: AbstractControl) => {
      if (predicate()) {
        return Validators.required(formControl);
      }
      return null;
    };
  }

  get isFormValid() {
    const mileageValid = this.valuationQuestionsForm.get('mileage').valid;
    const nameValid = this.valuationQuestionsForm.get('customerName').valid;
    const emailValid = this.valuationQuestionsForm.get('emailAddress').valid;
    const phoneValid = this.valuationQuestionsForm.get('telephone').valid;
    if (this.requireCustomerName) {
      return mileageValid && nameValid && emailValid && phoneValid;
    } else {
      return mileageValid;
    }
  }

  onNext() {
    this.loading = true;
    const mileage = this.valuationQuestionsForm.get('mileage').value;
    if (this.quoteStateId) {
      this.todoService
        .saveAnswerManually$(this.quoteStateId, 'Mileage', mileage, false)
        .pipe(
          tap(() => {
            this.dismissModal.emit(true);
            this.imageService.saveDbImage.emit(true);
            this.saveImage(this.quoteStateId);
            this.vehicleCheckService.getVehicleCheck$(this.vrm, this.quoteStateId, false).pipe(
              tap(result => {
                if (result.statusId !== VehicleLookupStatusTypes.Success) {
                  this.retry();
                } else {
                  this.navigate();
                }
              }),
              catchError(err => {
                this.notifications.dangerToast('Failed to retrieve HPI data.', err);
                return EMPTY;
              }),
            ).subscribe(() => {
            });

          }),
          catchError(() => {
            return EMPTY;
          }),
          take(1)
        )
        .subscribe();
    } else {
      const lookupData = new WalkInLookupData(this.quoteId, this.quoteGuid, this.vrm, mileage, null, null, null, null,
        this.valuationQuestionsForm.get('customerName').value, null,
        this.valuationQuestionsForm.get('emailAddress').value, this.valuationQuestionsForm.get('telephone').value);
      lookupData.vehicleImageUrl = this.vehicle.ImageUrl;
      lookupData.colour = this.vehicle.Colour;
      lookupData.altVrm = this.altVrm;
      lookupData.isManualLookup = this.sharedService.getIsManualLookup();

      this.valuationService
        .startWalkinValuation$(lookupData)
        .pipe(
          tap((result) => {
            if (result.requiresManualValuation) {
              this.requiresManualValuation.emit(true);
            } else if (result.highValueRequiresAppointment) {
              this.highValueRequiresAppointment.emit(lookupData);
            } else {
              this.quoteStateId = result.quoteStateId;
              this.dismissModal.emit(true);
              this.imageService.saveDbImage.emit(true);
              this.saveImage(result.quoteStateId);
              this.quoteStateId = result.quoteStateId;
              if (this.altVrm) {
                this.todoService
                .saveAnswerManually$(this.quoteStateId, 'AltVRM', 'true', false)
                .pipe(tap(() =>{
                  this.todoService.saveAnswerManually$(this.quoteStateId, 'IsBookedVrm', this.isBookedVrm ? 'true' : 'false', false).subscribe();
                }),
                  catchError(() => {
                    return EMPTY;
                  }),
                  take(1)
                )
                .subscribe();
              }
              this.navigate();
            }
          }),
          catchError((err) => {
            this.notifications.dangerToast('Failed to start purchase', err);
            return EMPTY;
          }),
          take(1)
        )
        .subscribe();
    }
  }

  navigate() {
    this.router.navigate(['/purchase', this.quoteStateId]);
  }

  saveImage(quoteStateId: number) {
    return this.imageService.submitImages$(quoteStateId, null, false).subscribe();
  }

  // prevents input from adding characters on tablets after max limit is reached
  // (very specific scenario, might be caused by a bug)
  preventInputOnTablet(inputMileage: string) {
    const inputLength = inputMileage.length;
    const isMaxInput = inputLength >= 8;
    if (isMaxInput) {
      if (isMaxInput) {
        inputMileage = inputMileage.slice(0, -1);
        this.valuationQuestionsForm.patchValue({
          mileage: inputMileage,
        });
      }
    }
  }

  retry() {
    const modalRef = this.modalService.open(HpiRetryComponent, {
      keyboard: false,
      backdrop: 'static',
      centered: true,
      size: 'lg',
    });
    modalRef.componentInstance.vrm = this.vrm;
    modalRef.componentInstance.appointmentId = 0;
    modalRef.componentInstance.thingToRetry$ = this.lookupService.findVehicle$(this.vrm);
    modalRef.componentInstance.hpiSuccess.subscribe(() => {
      this.navigate();
    });
  }

  scrollModalToTopPosition(topPositionRem: number) {
    ModalHelper.scrollModalToTopPosition(topPositionRem);
  }
}
