<ng-template #incomplete>
  <div class="loading-center">
    <app-loading-wbac-animation></app-loading-wbac-animation>
  </div>
</ng-template>

<div class="d-none d-lg-block" *ngIf="complete$ | async; else incomplete">
  <form [formGroup]="mileageForm" class="questionsForm mt-2">
    <div class="form-section mb-4">
      <div class="row">
        <div class="col-4">
          <div class="bold-label">Entered Mileage:</div>
          <input id="mileageInput" pattern="^[0-9]+([0-9]{0,0})?$" type="text" class="form-control input-text"
            placeholder="Entered Mileage" formControlName="mileage" (ngModelChange)="preventInputOnTablet($event)"
            maxlength="8" mask="separator" thousandSeparator="," required autocomplete="off"
            [readonly]="!questionForm" />
          <span class="placeholder">
            Entered Mileage
          </span>
          <span class="placeholder-line"></span>
        </div>
        <div class="col-1">
        </div>
        <div class="col-5">
          <div class="bold-label">Mileage Decision:</div>
          <app-list-input [group]="mileageDecisionControl" [options]="allowedDecisionOptions" customClass="small-two-line"
            alert="true" class="popup-button">
          </app-list-input>
        </div>
      </div>
    </div>
  </form>

  <div class="d-flex flex-row">
    <app-mot-history [provider]="'dvsa'" [mileageData]="dvsaMileage" [hasDiscrepancy]="hasDvsaDiscrepancy"
      [showMotHistory]="showDvsaHistory"></app-mot-history>
    <app-mot-history [provider]="'nmr'" [mileageData]="nmrMileage" [hasDiscrepancy]="hasNmrDiscrepancy"
      [showMotHistory]="showNmrHistory"></app-mot-history>
    <app-mot-history [provider]="'bca'" [mileageData]="bcaMileage" [hasDiscrepancy]="hasBcaDiscrepancy"
      [showMotHistory]="showBcaHistory"></app-mot-history>
  </div>
</div>

<div class="d-block d-lg-none" *ngIf="complete$ | async; else incomplete">
  <div class="d-flex flex-col">
    <app-mot-history [provider]="'dvsa'" [mileageData]="dvsaMileage" [hasDiscrepancy]="hasDvsaDiscrepancy"
      [showMotHistory]="showDvsaHistory"></app-mot-history>
    <app-mot-history [provider]="'nmr'" [mileageData]="nmrMileage" [hasDiscrepancy]="hasNmrDiscrepancy"
      [showMotHistory]="showNmrHistory"></app-mot-history>
    <app-mot-history [provider]="'bca'" [mileageData]="bcaMileage" [hasDiscrepancy]="hasBcaDiscrepancy"
      [showMotHistory]="showBcaHistory"></app-mot-history>
  </div>
</div>