import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { catchError, debounceTime, switchMap, tap } from 'rxjs/operators';
import { EMPTY, Subject, Subscription } from 'rxjs';
import { Appointment } from '../models/appointment.model';
import { BuyerCalendarSchedule } from '../models/buyer-calendar-schedule.model';
import { CalendarHelper } from '../helpers/calendar-helper';
import { DayTypes } from '../../enums/day-types';
import { DayDetails } from '../models/day-details.model';
import { NotificationService } from '../../services/notification.service';
import moment, { Moment } from 'moment';
import { Site } from '../models/site.model';
import { ScrollTypes } from '../../enums/scroll-types';
import { SiteNameAbbreviationHelper } from '../../helpers/site-name-abbreviation-helper';
import { AppointmentService } from '../../services/appointment.service';

@Component({
  selector: 'app-week-view',
  templateUrl: './week-view.component.html',
  styleUrls: ['./week-view.component.scss']
})

export class WeekViewComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(private changeDetection: ChangeDetectorRef, private appointmentService: AppointmentService, private notifications: NotificationService) { }

  @Input() currentDate: number;
  @Input() currentTime: number;
  @Input() dayHours: number[];
  @Input() dayStartHour: number;
  @Input() dayEndHour: number;
  @Input() defaultDisplayStartHour: number;
  @Input() buyerWeeklySchedule: BuyerCalendarSchedule;

  startWeekDate: Moment;
  startWeekDateFormated: string;
  endWeekDate: Moment;
  weekDetails = new Array<DayDetails>();
  calendarHeight: number;
  scrollTypes = ScrollTypes;
  isCurrentDaySunday: boolean;

  buyerCalendarSubject = new Subject();
  buyerCalendarObservable$ = this.buyerCalendarSubject.asObservable();
  buyerCalendarSubscription: Subscription;

  @ViewChild('calendar') calendar: ElementRef;

  ngOnInit() {
    this.isCurrentDaySunday = moment(this.currentDate).day() === 0;
    this.buyerCalendarSubscription = this.buyerCalendarObservable$.pipe(
      debounceTime(300),
      switchMap(() => this.getBuyerWeeklySchedule$())
    ).subscribe();
    this.formatStartWeekDate();
  }

  ngAfterViewInit() {
    this.calendarHeight = CalendarHelper.getCalendarHeight(this.calendar);
    CalendarHelper.setDefaultScrollPostion(this.defaultDisplayStartHour, this.dayStartHour, this.dayHours, this.calendarHeight);
    this.getBuyerWeeklySchedule$().subscribe();
    this.changeDetection.detectChanges();
  }

  ngOnDestroy() {
    if (this.buyerCalendarSubscription) {
      this.buyerCalendarSubscription.unsubscribe();
    }
  }

  getBuyerWeeklySchedule$() {
    return this.appointmentService.getBuyerWeeklySchedule(moment(this.currentDate).format('YYYY-MM-DD')).pipe(
      tap(result => {
        this.buyerWeeklySchedule = result;
        this.getWeekDayDetails();
        this.changeDetection.detectChanges();
      }),
      catchError(() => {
        return EMPTY;
      }));
  }

  changeDate(scrollType: ScrollTypes) {
    if (scrollType === ScrollTypes.Next) {
      this.currentDate = moment(this.currentDate).add(1, 'week').valueOf();
    } else if (scrollType === ScrollTypes.Previous) {
      this.currentDate = moment(this.currentDate).add(-1, 'week').valueOf();
    } else {
      this.currentDate = Date.now();
    }
    this.formatStartWeekDate();
    this.buyerCalendarSubject.next(null);
  }

  getWeekDayDetails() {
    this.weekDetails = new Array<DayDetails>();
    const allAppointments = new Array<Appointment>();
    this.buyerWeeklySchedule.appointments.forEach(x => allAppointments.push(new Appointment(x, this.buyerWeeklySchedule.buyer.durationInMinutes)));
    if (this.buyerWeeklySchedule) {
      let scheduleDayDate: moment.Moment;
      let lastRecordedDate = this.isCurrentDaySunday ?
        moment(this.currentDate).add(-1, 'week').day(1) :
        moment(this.currentDate).day(1);

      const abbreviatedSites = new Map<string, string>();

      for (let dayId = 1; dayId <= 7; dayId++) {
        // when 7 (Sunday) then change dayId to 0
        const weekDayId = dayId === 7 ? 0 : dayId;

        const dayAppointments = allAppointments.filter(x => x.dayId === weekDayId);
        const dayDate = dayAppointments[0] ? moment(dayAppointments[0].appointmentTime).toDate() : null;

        if (!dayDate && dayId === 1) {
          scheduleDayDate = moment(lastRecordedDate);
        } else if (!dayDate) {
          scheduleDayDate = moment(lastRecordedDate).add(1, 'day');
        } else {
          scheduleDayDate = moment(dayDate);
        }

        const site = this.buyerWeeklySchedule.sites.find(x => x.siteDays.find(y => y?.dayId === weekDayId));
        this.getAbbreviatedSiteName(site, abbreviatedSites);
        const buyer = this.buyerWeeklySchedule.buyer;
        const formattedDayNumber = this.formatDayNumberToDisplay(scheduleDayDate, lastRecordedDate);
        const dayType = this.getDayType(scheduleDayDate.startOf('day'), moment(this.currentTime).startOf('day'));

        this.weekDetails.push(
          new DayDetails(
            weekDayId,
            formattedDayNumber,
            scheduleDayDate.format('dddd'),
            site,
            dayType,
            buyer,
            dayAppointments,
            this.calendarHeight,
            this.dayHours.length,
            scheduleDayDate.valueOf()
          )
        );

        lastRecordedDate = scheduleDayDate;
      }
    }
  }

  formatDayNumberToDisplay(day: moment.Moment, lastRecordedDate: moment.Moment): string {
    let formattedDayNumber = day.format('D');
    if (moment(day).month() !== moment(lastRecordedDate).month()) {
      formattedDayNumber += day.format(' MMMM');
    }
    return formattedDayNumber;
  }

  formatStartWeekDate() {
    if (this.isCurrentDaySunday) {
      this.startWeekDate = moment(this.currentDate).add(-1, 'week').day(1);
      this.endWeekDate = moment(this.currentDate).day(0);
    } else {
      this.startWeekDate = moment(this.currentDate).day(1);
      this.endWeekDate = moment(this.currentDate).day(7);
    }

    if (this.startWeekDate.year() !== this.endWeekDate.year()) {
      this.startWeekDateFormated = this.startWeekDate.format('D MMMM y');
    } else {
      this.startWeekDateFormated = this.startWeekDate.format('D MMMM');
    }
  }

  getDayType(calendarDay: moment.Moment, currentDay: moment.Moment) {
    if (calendarDay.isSame(currentDay)) {
      return DayTypes.Current;
    } else if (calendarDay.isBefore(currentDay)) {
      return DayTypes.Past;
    }
  }

  private getAbbreviatedSiteName(site: Site, abbreviatedSites: Map<string, string>) {
    if (site?.name) {
      let abbreviatedSiteName: string;
      if (!abbreviatedSites.has(site.name)) {
        abbreviatedSiteName = SiteNameAbbreviationHelper.abbreviateSiteName(site.name);
        abbreviatedSites.set(site.name, abbreviatedSiteName);
      } else {
        abbreviatedSiteName = abbreviatedSites.get(site.name);
      }
      site.abbreviatedSiteName = abbreviatedSiteName;
    }
  }

}


