<div [formGroup]="questionForm" [ngClass]="{'valuation-state container': stateName === 'Your Offer'}">
  <ng-container formArrayName="items" *ngFor="let item of questionForm.get('items')['controls']; let i = index;">
    <div *ngIf="item.controls.controlType.value != 0 && showItem(item)"
        class="question-container container" [formGroupName]="i"
        [ngClass]="{
          'multiselect-content': questionForm.value.items[i].controlType === controlTypes.MULTI_SELECT_LIST_RADIO,
          'hidden': questionForm.value.items[i].isHidden
          }">
      <ng-container [ngSwitch]="questionForm.value.items[i].controlType">

        <h4 *ngIf="questionForm.value.items[i].heading">{{ questionForm.value.items[i].heading }}</h4>

        <app-text-input *ngSwitchCase="controlTypes.TEXT_ENTRY" [group]="item"
          (change)="onAnswerChange($event)" (keyup.enter)="confirmEntry($event)">
        </app-text-input>
        <app-number-input *ngSwitchCase="controlTypes.NUMBER_ENTRY" [group]="item"
          (change)="onAnswerChange($event)" (keyup.enter)="confirmEntry($event)">
        </app-number-input>
        <app-postcode-lookup-input *ngSwitchCase="controlTypes.ADDRESS_LOOKUP" [group]="item" [questionForm]="questionForm"
          (change)="onAnswerChange($event)">
        </app-postcode-lookup-input>
        <app-vat-check-input *ngSwitchCase="controlTypes.VAT_LOOKUP" [group]="item" [questionForm]="questionForm" [quoteStateId]="quoteStateId"
          (change)="onAnswerChange($event)">
        </app-vat-check-input>
        <div *ngSwitchCase="controlTypes.DATE_ENTRY">
          <app-date-input [group]="item" (change)="onAnswerChange($event)"
            (keyup.enter)="confirmEntry($event)">
          </app-date-input>
        </div>
        <app-list-input *ngSwitchCase="controlTypes.INLINE_LIST_RADIO" [group]="item"
          [options]="getOptions(questionForm.value.items[i].name)" (change)="onAnswerChange($event)">
        </app-list-input>
        <app-list-input *ngSwitchCase="controlTypes.INLINE_BOOL_RADIO" [group]="item"
          [options]="getOptions(questionForm.value.items[i].name)" (change)="onAnswerChange($event)">
        </app-list-input>
        <app-list-input *ngSwitchCase="controlTypes.VERTICAL_BUTTON_LIST" [group]="item" [vertical]="true"
          [options]="getOptions(questionForm.value.items[i].name)" (change)="onAnswerChange($event)">
        </app-list-input>
        <app-multi-select-input *ngSwitchCase="controlTypes.MULTI_SELECT_LIST_RADIO" [group]="item" [halfWidth]="column > 0"
          [options]="getOptions(questionForm.value.items[i].name)" [questionForm]="questionForm" (change)="onAnswerChange($event)">
        </app-multi-select-input>
        <app-radio-input *ngSwitchCase="controlTypes.LIST_RADIO" [vertical]="true" [group]="item"
          [options]="getOptions(questionForm.value.items[i].name)" (change)="onAnswerChange($event)">
        </app-radio-input>
        <app-radio-input *ngSwitchCase="controlTypes.BOOL_RADIO" [vertical]="true" [group]="item"
          [options]="getOptions(questionForm.value.items[i].name)" (change)="onAnswerChange($event)">
        </app-radio-input>
        <app-searchable-dropdown-input *ngSwitchCase="controlTypes.SEARCHABLE_DROPDOWN" [group]="item"
          [options]="getOptions(questionForm.value.items[i].name)" (change)="onAnswerChange($event)"
          (keyup.enter)="confirmEntry($event)">
        </app-searchable-dropdown-input>
        <app-dropdown-input *ngSwitchCase="controlTypes.LIST_DROPDOWN" [group]="item"
          [options]="getOptions(questionForm.value.items[i].name)" (change)="onAnswerChange($event)">
        </app-dropdown-input>
        <ng-container *ngSwitchCase="controlTypes.VALUATION">
          <app-valuation class="w-100" [group]="item" [quoteStateId]="quoteStateId" [isAppraisalFinalised]="isAppraisalFinalised">
          </app-valuation>
        </ng-container>

        <ng-container *ngSwitchCase="controlTypes.HPICHECK">
          <app-vehicle-check [quoteStateId]="quoteStateId" [vrm]="vrm" [vehicleDetails]="vehicleDetails" [mileage]="mileage" [ancestryCategory]="ancestryCategory" [group]="item"
            [questionForm]="questionForm" [writeOffOptions]="getOptions(questionForm.value.items[i].name)" (change)="onAnswerChange($event)">
          </app-vehicle-check>
        </ng-container>

        <ng-container *ngSwitchCase="controlTypes.DAMAGE">
          <app-damage-total [quoteStateId]="quoteStateId" [group]="item" [questionForm]="questionForm" [answers]="updatedAnswers">
          </app-damage-total>
        </ng-container>
        <ng-container *ngSwitchCase="controlTypes.DAMAGE_COSTS">
          <app-damage-cost [quoteStateId]="quoteStateId" [questionForm]="questionForm" [group]="item" (change)="onAnswerChange($event)">
          </app-damage-cost>
        </ng-container>
        <ng-container *ngSwitchCase="controlTypes.APPRAISAL_REPORT">
          <app-appraisal-report [quoteStateId]="quoteStateId" [group]="item" [canLoadContent]="answersSaved">
          </app-appraisal-report>
        </ng-container>

        <ng-container *ngSwitchCase="controlTypes.MILEAGECHECK">
          <app-mileage [quoteStateId]="quoteStateId" [vrm]="vrm" [group]="item" [questionForm]="questionForm" [mileage]="mileage" [page]="questionPages[0]">
          </app-mileage>
        </ng-container>
        <ng-container *ngSwitchCase="controlTypes.VEHICLE_IDENTIFICATION">
          <div class="vehicle-identification">
            <div class="capChangeWarning" *ngIf="capIdChangedOnStart">The model identified by the customer does not match the model returned by HPI. The HPI model will be used from now on.</div>
            <app-vehicle-image [vehicleImage]='vehicleDetails["imageUrl"]' [defaultVehicleImage]='defaultVehicleImage' 
            [isStartPurchase]='true'>
            </app-vehicle-image>
            <div class="align-plates">
              <app-registration-plate [isStartPurchase]='true' [vrm]='vrm'></app-registration-plate>
              <div *ngIf="(isChangeVrmFlow || isAltVRMFlow)"><img src="../../assets/right-arrow.svg" class="m-5"></div>
              <app-registration-plate *ngIf="(isChangeVrmFlow || isAltVRMFlow)" [isStartPurchase]='true' [vrm]='changeVrm'></app-registration-plate>
            </div>
            <app-vehicle-details [questionForm]='item' [mileage]='mileage' [quoteStateId]="quoteStateId" [vrm]="vrm"
                                  [vehicleDetails]='vehicleDetails' [hideDetails]='false' [HPIFirstRegistered]="hpiFirstRegistered">
            </app-vehicle-details>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="controlTypes.SERVICE_HISTORY_WARRANTY_CHECK">
          <app-service-history-warranty-check [mileage]="mileage" [quoteStateId]="quoteStateId"
            [vrm]="vrm" [group]="item" [questionForm]='questionForm'>
          </app-service-history-warranty-check>
        </ng-container>

        <ng-container *ngSwitchCase="controlTypes.SERVICE_HISTORY_SERVICE_INTERVAL_CHECK">
          <app-service-interval-check [save]="saveNotifier" [mileage]="mileage" [quoteStateId]="quoteStateId"
            [vrm]="vrm" [group]="item">
          </app-service-interval-check>
        </ng-container>

        <ng-container *ngSwitchCase="controlTypes.SERVICE_HISTORY_FINAL_STATE">
          <app-display-service-history-state [group]="item" [quoteStateId]="quoteStateId">
          </app-display-service-history-state>
        </ng-container>

        <ng-container *ngSwitchCase="controlTypes.OPTIONAL_EXTRAS">
          <app-optional-extras [group]="item" [stateName]="stateName" [quoteStateId]="quoteStateId" [questionForm]='questionForm'>
          </app-optional-extras>
        </ng-container>

        <ng-container *ngSwitchCase="controlTypes.BANK_VERIFICATION_SERVICE">
          <app-bank-verification-service [group]="item" [questionForm]='questionForm' [quoteStateId]="quoteStateId" [answers]="updatedAnswers">
          </app-bank-verification-service>
        </ng-container>

        <ng-container *ngSwitchCase="controlTypes.BANK_DETAILS_LOOKUP_TABLE">
          <app-bank-verification [group]="item" [questionForm]='questionForm' [questionPages]="questionPages" [quoteStateId]="quoteStateId">
          </app-bank-verification>
        </ng-container>

        <ng-container *ngSwitchCase="controlTypes.IMAGE_UPLOAD_PAGE">
          <app-image-input [save]="saveNotifier" [parameterName] ="null" [group]= "item" [stateName]="stateName" [quoteStateId]="quoteStateId" [questionForm]='questionForm'
            [label]="" [displayImages]="true" [hasManualImageInput]="false" [requiredImageType]="requiredImageType" [imageData]="questionForm.value.items[i].images">
          </app-image-input>
        </ng-container>

        <ng-container *ngSwitchCase="controlTypes.VIDEO_OF_VEHICLE">
          <app-video-input [save]="saveNotifier" [group]="item" [quoteStateId]="quoteStateId">
          </app-video-input>
        </ng-container>

        <ng-container *ngSwitchCase="controlTypes.OUTSTANDING_FINANCE">
          <app-outstanding-finance [vrm]="vrm" [group]="item" [questionForm]='questionForm' [quoteStateId]="quoteStateId">
          </app-outstanding-finance>
        </ng-container>

        <ng-container *ngSwitchCase="controlTypes.CUSTOMER_GUARANTEES">
          <app-customer-guarantees [group]="item">
          </app-customer-guarantees>
        </ng-container>

        <ng-container *ngSwitchCase="controlTypes.PAYMENT_OPTIONS">
          <app-payment-options [group]="item" [quoteStateId]="quoteStateId" [questionForm]='questionForm' [canLoadContent]="answersSaved">
          </app-payment-options>
        </ng-container>

        <ng-container *ngSwitchCase="controlTypes.SIGN_INVOICE">
          <app-invoice [quoteStateId]="quoteStateId" [group]="item" [canLoadContent]="answersSaved">
          </app-invoice>
        </ng-container>

        <ng-container *ngSwitchCase="controlTypes.CONGRATULATIONS">
          <app-congratulations [quoteStateId]="quoteStateId" [group]="item" (stateReturn)="onGoBackInState($event)">
          </app-congratulations>
        </ng-container>

        <ng-container *ngSwitchCase="controlTypes.IMAGE_UPLOAD_QUESTION">
          <app-image-input [save]="saveNotifier" [quoteStateId]="quoteStateId" [stateName]="stateName" [group]="item" [parameterName]="item.controls.name.value"
            [label]="questionForm.value.items[i].question" [imageData]="questionForm.value.items[i].images" [questionForm]="questionForm"
            [hasManualImageInput]="questionForm.value.items[i].hasManualImageInput"
            (finalisedNotifier)="onAnswerChange($event)" [requiredImageType]="requiredImageType">
          </app-image-input>
        </ng-container>

        <ng-container *ngSwitchCase="controlTypes.REQUIRED_TO_BUY">
          <app-required-to-buy [quoteStateId]="quoteStateId" [group]="item" [questionForm]="questionForm" [defaults]="questionDefaults" [vrm]="vrm"></app-required-to-buy>
        </ng-container>

        <ng-container *ngSwitchCase="controlTypes.SIGN_D2">
          <app-d2-form [quoteStateId]="quoteStateId" [group]="item" [canLoadContent]="answersSaved">
          </app-d2-form>
        </ng-container>
        
        <app-vin-input *ngSwitchCase="controlTypes.VIN_ENTRY" [group]="item"
          (change)="onAnswerChange($event)" (keyup.enter)="confirmEntry($event)" [questionForm]="questionForm">
        </app-vin-input>

      </ng-container>
    </div>
  </ng-container>
</div>