import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { catchError, concatMap, takeUntil, tap } from 'rxjs/operators';
import { KeyValuePair } from '../../models/key-value-pair.model';
import { BaseComponentDirective } from '../../base/base.component';
import { NotificationService } from '../../services/notification.service';
import { ServiceHistoryService } from '../../services/service-history.service';
import { VehicleCheckService } from '../../services/vehicle-check.service';
import { VehicleCheck } from '../../vehicle-check-hpi/models/vehicle-check.model';
import { WarrantyCheckRequest } from '../models/warranty-check-request.model';
import { WarrantyCheckResult } from '../models/warranty-check-result.model';
import { LoadingService } from '../../services/loading.service';

@Component({
    selector: 'app-service-history-warranty-check',
    templateUrl: './service-history-warranty-check.component.html',
    styleUrls: ['./service-history-warranty-check.component.scss'],
})
export class ServiceHistoryWarrantyCheckComponent extends BaseComponentDirective implements OnInit {

    @Input() quoteStateId: number;
    @Input() vrm: string;
    @Input() group: UntypedFormGroup;
    @Input() questionForm: UntypedFormGroup;
    @Input() mileage = 0;
    @Output() change = new EventEmitter<UntypedFormGroup>();

    loadingError$ = new BehaviorSubject<boolean>(false);
    ageInYears: number;
    minDateDescription: string;
    manufacturer: string;
    vehicleAndWarrantyCheck$: Observable<WarrantyCheckResult>;

    vehicleCheck$: Observable<VehicleCheck>;
    warrantyCheck$: Observable<WarrantyCheckResult>;
    vehicleCheckError = new BehaviorSubject<boolean>(false);
    warrantyCheckError = new BehaviorSubject<boolean>(false);
    vehicleNotFoundError = new BehaviorSubject<boolean>(false);

    isVehicleDetailsCollapsed: boolean;
    isInWarranty: boolean;
    showFirstServiceNotDue = false;
    isFirstServiceNotDue = false;
    isNoValidServiceRecords = false;
    isElectronicServiceHistory = false;
    isServiceBook = false;
    isServiceInvoice = false;
    isServiceRecordsPhotos = false;
    warrantyStatusMessage: string;
    mileageBetweenServices: UntypedFormGroup;
    monthsBetweenServices: UntypedFormGroup;
    electronicServiceHistory: UntypedFormGroup;
    serviceBook: UntypedFormGroup;
    serviceInvoice: UntypedFormGroup;
    electronicServiceHistoryDate: UntypedFormGroup;
    serviceBookDate: UntypedFormGroup;
    serviceInvoiceDate: UntypedFormGroup;
    electronicServiceHistoryMileage: UntypedFormGroup;
    serviceBookMileage: UntypedFormGroup;
    serviceInvoiceMileage: UntypedFormGroup;
    serviceRecordsPhotos: UntypedFormGroup;
    serviceHistoryFormValid: UntypedFormGroup;
    firstNotDue: UntypedFormGroup;
    noValidServiceRecords: UntypedFormGroup;
    runningInService: UntypedFormGroup;
    minDate: Date;
    maxDate: Date;
    warrantyAndServicesDateInputStyling: boolean;
    requiresRunningInService: boolean;
    isCountValidElectronic: boolean;
    isCountValidServiceBook: boolean;
    isCountValidServiceInvoice: boolean;

    manufacturerWarrantyMileage: number;
    manufacturerWarrantyAge: number;

    leniency = 1.075;

    dateNow = new Date();

    yesNoOptions: Array<KeyValuePair>;

    constructor(private vehicleCheckService: VehicleCheckService,
        private serviceHistoryService: ServiceHistoryService,
        private loadingService: LoadingService,
        private notifications: NotificationService) {
        super();
    }

    @ViewChild('retryButton', { read: ElementRef, static: true }) retryButton: ElementRef;

    ngOnInit() {
        this.loadingService.loading.emit(true);
        this.warrantyAndServicesDateInputStyling = true;
        this.createWarrantyAndServicesFormGroup();
        this.createVehicleAndWarrantyCheckObservable();

        this.serviceRecordsPhotos.controls.value.valueChanges.subscribe(() => {
            this.setFormStatus();
        });

        this.yesNoOptions = new Array<KeyValuePair>();
        this.yesNoOptions.push(new KeyValuePair('1', 'Yes'));
        this.yesNoOptions.push(new KeyValuePair('0', 'No'));
        if (this.hasPreviousAnswers()) {
            this.isElectronicServiceHistory = this.electronicServiceHistory.value.value > 0;
            this.isServiceRecordsPhotos = this.serviceRecordsPhotos.value.value !== null;
            this.isServiceBook = this.serviceBook.value.value > 0;
            this.isServiceInvoice = this.serviceInvoice.value.value > 0;
            this.isFirstServiceNotDue = this.firstNotDue.value.value === 'true';
            this.isNoValidServiceRecords = !(this.isFirstServiceNotDue || this.isElectronicServiceHistory || this.isServiceBook || this.isServiceInvoice);
            this.showHideServiceRecordsPhotos();
        }
    }

    hasPreviousAnswers(): boolean {
        return this.serviceHistoryFormValid && this.serviceHistoryFormValid.value.value;
    }

    areServiceIntervalsValid(): boolean {
        if (this.isInWarranty) {
            return this.mileageBetweenServices.controls.value.value > 0 && this.monthsBetweenServices.controls.value.value > 0;
        }
        return true;
    }

    areServiceRecordsValid(): boolean {
        if (this.isNoValidServiceRecords || this.isFirstServiceNotDue) {
            this.checkFirstNotDue();
            return true;
        } else if (!this.isElectronicServiceHistory && !this.isServiceBook && !this.isServiceInvoice && !this.isServiceRecordsPhotos) {
            return false;
        } else {
            return this.isElectronicServiceRecordValid() && this.isServiceBookRecordValid() && this.isServiceInvoiceRecordValid() && this.isServiceRecordsPhotosValid();
        }
    }

    isRunningInServiceValid(): boolean {
        if (!this.requiresRunningInService) {
            return true;
        }
        return this.runningInService.controls.value.value !== null;
    }

    setFormStatus() {
        const isValid = this.areServiceIntervalsValid() && this.areServiceRecordsValid() && this.isRunningInServiceValid();
        if (isValid) {
            this.serviceHistoryFormValid.patchValue({ value: true });
        } else {
            this.serviceHistoryFormValid.patchValue({ value: null });
        }
    }

    toggleFirstNotDue() {
        this.setFirstServiceNotDue(!this.isFirstServiceNotDue);
        if (this.isFirstServiceNotDue) {
            this.setNoValidServiceRecords(false);
            this.clearServiceCounts();
        }
        this.setFormStatus();
    }

    checkFirstNotDue() {
        if (this.isFirstServiceNotDue && !this.isNoValidServiceRecords) {
            this.setNoValidServiceRecords(false);
            this.clearServiceCounts();
        }
    }

    setFirstServiceNotDue(serviceNotDue: boolean) {
        this.isFirstServiceNotDue = serviceNotDue;
        this.firstNotDue.patchValue({ value: serviceNotDue });
    }

    checkFirstNotDueStatus() {
        const firstRegistrationDate = new Date(this.minDate);
        const monthDifference = (this.dateNow.getFullYear() - firstRegistrationDate.getFullYear()) * 12 +
                      (this.dateNow.getMonth() - firstRegistrationDate.getMonth());
        this.showFirstServiceNotDue = this.mileage < this.mileageBetweenServices.controls.value.value &&
                                monthDifference < (this.monthsBetweenServices.controls.value.value * this.leniency);
        if (this.showFirstServiceNotDue === false) {
            this.setFirstServiceNotDue(false);
        }
        this.setFormStatus();
    }

    toggleNoValidServiceRecords() {
        this.setNoValidServiceRecords(!this.isNoValidServiceRecords);
        if (this.isNoValidServiceRecords) {
            this.setFirstServiceNotDue(false);
            this.clearServiceCounts();
        }
        this.updateServiceRecordsPhotos();
        this.setFormStatus();
    }

    setNoValidServiceRecords(noValidServiceRecords: boolean) {
        this.isNoValidServiceRecords = noValidServiceRecords;
        this.noValidServiceRecords.patchValue({ value: noValidServiceRecords });
    }

    toggleElectronicServiceRecords() {
        this.isElectronicServiceHistory = !this.isElectronicServiceHistory;
        if (this.isElectronicServiceHistory) {
            this.setNoValidServiceRecords(false);
            this.setFirstServiceNotDue(false);
            this.addValidators(this.electronicServiceHistory, this.electronicServiceHistoryDate, this.electronicServiceHistoryMileage);
        } else {
            this.electronicServiceHistory.patchValue({ value: null });
            this.electronicServiceHistoryDate.patchValue({ value: null });
            this.electronicServiceHistoryMileage.patchValue({ value: null });
            this.removeValidators(this.electronicServiceHistory, this.electronicServiceHistoryDate, this.electronicServiceHistoryMileage);
        }
        this.updateServiceRecordsPhotos();
        this.setFormStatus();
    }

    toggleServiceBookRecords() {
        this.isServiceBook = !this.isServiceBook;
        if (this.isServiceBook) {
            this.setNoValidServiceRecords(false);
            this.setFirstServiceNotDue(false);
            this.addValidators(this.serviceBook, this.serviceBookDate, this.serviceBookMileage);
        } else {
            this.serviceBook.patchValue({ value: null });
            this.serviceBookDate.patchValue({ value: null });
            this.serviceBookMileage.patchValue({ value: null });
            this.removeValidators(this.serviceBook, this.serviceBookDate, this.serviceBookMileage);
        }
        this.updateServiceRecordsPhotos();
        this.setFormStatus();
    }

    toggleServiceInvoiceRecords() {
        this.isServiceInvoice = !this.isServiceInvoice;
        if (this.isServiceInvoice) {
            this.setNoValidServiceRecords(false);
            this.setFirstServiceNotDue(false);
            this.addValidators(this.serviceInvoice, this.serviceInvoiceDate, this.serviceInvoiceMileage);
        } else {
            this.serviceInvoice.patchValue({ value: null });
            this.serviceInvoiceDate.patchValue({ value: null });
            this.serviceInvoiceMileage.patchValue({ value: null });
            this.removeValidators(this.serviceInvoice, this.serviceInvoiceDate, this.serviceInvoiceMileage);
        }
        this.updateServiceRecordsPhotos();
        this.setFormStatus();
    }

    isElectronicServiceRecordValid() {
        if (this.isElectronicServiceHistory) {
            const isCountValid = this.electronicServiceHistory.controls.value.value >= 1;
            this.isCountValidElectronic = isCountValid;
            let isDataValid = true;
            if (!this.isInWarranty) {
                isDataValid = this.electronicServiceHistoryDate.controls.value.valid && this.electronicServiceHistoryMileage.controls.value.valid;
            }
            return isCountValid && isDataValid;
        } else {
            return true;
        }
    }

    isServiceBookRecordValid() {
        if (this.isServiceBook) {
            const isCountValid = this.serviceBook.controls.value.value >= 1;
            this.isCountValidServiceBook = isCountValid;
            let isDataValid = true;
            if (!this.isInWarranty) {
                isDataValid = this.serviceBookDate.controls.value.valid && this.serviceBookMileage.controls.value.valid;
            }
            return isCountValid && isDataValid;
        } else {
            return true;
        }
    }

    isServiceInvoiceRecordValid() {
        if (this.isServiceInvoice) {
            const isCountValid = this.serviceInvoice.controls.value.value >= 1;
            this.isCountValidServiceInvoice = isCountValid;
            let isDataValid = true;
            if (!this.isInWarranty) {
                isDataValid = this.serviceInvoiceDate.controls.value.valid && this.serviceInvoiceMileage.controls.value.valid;
            }
            return isCountValid && isDataValid;
        } else {
            return true;
        }
    }

    showHideServiceRecordsPhotos() {
        if (this.isServiceRecordsPhotos) {
            this.serviceRecordsPhotos.controls.isHidden.patchValue(false);
            this.serviceRecordsPhotos.value.isHidden = false;
        } else {
            this.serviceRecordsPhotos.controls.isHidden.patchValue(true);
            this.serviceRecordsPhotos.value.isHidden = true;
        }
    }

    isServiceRecordsPhotosValid() {
        if (this.isServiceRecordsPhotos) {
            return this.serviceRecordsPhotos.controls.value.value !== null;
        } else {
            return true;
        }
    }

    updateServiceRecordsPhotos() {
        if ((this.isServiceInvoice || this.isElectronicServiceHistory || this.isServiceBook) && !this.isInWarranty) {
            this.isServiceRecordsPhotos = true;
            this.showHideServiceRecordsPhotos();
            this.addValidatorsServiceRecordsPhotos(this.serviceRecordsPhotos);
        } else if (!this.isInWarranty) {
            this.isServiceRecordsPhotos = false;
            this.showHideServiceRecordsPhotos();
            this.removeValidatorsServiceRecordsPhotos(this.serviceRecordsPhotos);
        }
    }

    clearServiceCounts() {
        this.isElectronicServiceHistory = false;
        this.isServiceBook = false;
        this.isServiceInvoice = false;
        this.electronicServiceHistory.patchValue({ value: null });
        this.electronicServiceHistoryDate.patchValue({ value: null });
        this.electronicServiceHistoryMileage.patchValue({ value: null });
        this.serviceBook.patchValue({ value: null });
        this.serviceBookDate.patchValue({ value: null });
        this.serviceBookMileage.patchValue({ value: null });
        this.serviceInvoice.patchValue({ value: null });
        this.serviceInvoiceDate.patchValue({ value: null });
        this.serviceInvoiceMileage.patchValue({ value: null });
        this.removeValidators(this.electronicServiceHistory, this.electronicServiceHistoryDate, this.electronicServiceHistoryMileage);
        this.removeValidators(this.serviceBook, this.serviceBookDate, this.serviceBookMileage);
        this.removeValidators(this.serviceInvoice, this.serviceInvoiceDate, this.serviceInvoiceMileage);
    }

    addValidatorsServiceRecordsPhotos(field: UntypedFormGroup) {
        field.controls.value.setValidators([Validators.required, Validators.max(1)]);
        field.controls.value.updateValueAndValidity();
    }

    removeValidatorsServiceRecordsPhotos(field: UntypedFormGroup) {
        field.controls.value.clearValidators();
        field.controls.value.updateValueAndValidity();
    }

    addValidators(countField: UntypedFormGroup, dateField: UntypedFormGroup, numberField: UntypedFormGroup) {
        countField.controls.value.setValidators([Validators.required, Validators.min(1)]);
        countField.controls.value.updateValueAndValidity();
        if (!this.isInWarranty) {
            dateField.controls.value.setValidators(Validators.required);
            dateField.controls.value.updateValueAndValidity();
            numberField.controls.value.setValidators([Validators.required, Validators.min(1), Validators.max(1000000)]);
            numberField.controls.value.updateValueAndValidity();
        }
    }

    removeValidators(countField: UntypedFormGroup, dateField: UntypedFormGroup, mileageField: UntypedFormGroup) {
        countField.controls.value.clearValidators();
        countField.controls.value.updateValueAndValidity();
        dateField.controls.value.clearValidators();
        dateField.controls.value.updateValueAndValidity();
        mileageField.controls.value.clearValidators();
        mileageField.controls.value.updateValueAndValidity();
    }

    createWarrantyAndServicesFormGroup() {
        if (this.group) {
            this.isInWarranty = this.group.controls.value.value;
        }
        if (this.questionForm) {
            const controlArray = this.questionForm.get('items') as UntypedFormArray;
            this.mileageBetweenServices = controlArray.controls[1] as UntypedFormGroup;
            this.monthsBetweenServices = controlArray.controls[2] as UntypedFormGroup;
            this.electronicServiceHistory = controlArray.controls[3] as UntypedFormGroup;
            this.serviceBook = controlArray.controls[4] as UntypedFormGroup;
            this.serviceInvoice = controlArray.controls[5] as UntypedFormGroup;
            this.electronicServiceHistoryDate = controlArray.controls[6] as UntypedFormGroup;
            this.serviceBookDate = controlArray.controls[7] as UntypedFormGroup;
            this.serviceInvoiceDate = controlArray.controls[8] as UntypedFormGroup;
            this.serviceHistoryFormValid = controlArray.controls[9] as UntypedFormGroup;
            this.firstNotDue = controlArray.controls[10] as UntypedFormGroup;
            this.noValidServiceRecords = controlArray.controls[11] as UntypedFormGroup;
            this.runningInService = controlArray.controls[12] as UntypedFormGroup;
            this.serviceRecordsPhotos = controlArray.controls[13] as UntypedFormGroup;
            this.electronicServiceHistoryMileage = controlArray.controls[14] as UntypedFormGroup;
            this.serviceBookMileage = controlArray.controls[15] as UntypedFormGroup;
            this.serviceInvoiceMileage = controlArray.controls[16] as UntypedFormGroup;
        }
    }

    createVehicleAndWarrantyCheckObservable() {
        this.vehicleAndWarrantyCheck$ = this.vehicleCheckService.getVehicleCheck$(this.vrm, this.quoteStateId, true).pipe(
            tap(result => {
                this.vehicleCheckError.next(false);
                if (result.vehicles.length > 0) {
                    this.ageInYears = result.vehicles[0].ageInYears;
                    this.manufacturer = result.vehicles[0].manufacturer;

                    if (result.vehicles[0].imported) {
                        this.minDate = result.calculatedManufacturedDate;
                        this.minDateDescription = 'Manufactured Date';
                    } else {
                        this.minDate = result.vehicles[0].ukDateOfFirstRegistration;
                        this.minDateDescription = 'First registration date';
                    }

                    this.maxDate = new Date();
                    this.loadingError$.next(false);
                    this.loadingService.loading.emit(false);
                    this.vehicleNotFoundError.next(false);
                } else {
                    this.loadingService.loading.emit(false);
                    this.loadingError$.next(true);
                    this.vehicleNotFoundError.next(true);
                }
            }),
            concatMap(() => {
                // No HPI data, set vehicle out of warranty
                if (this.manufacturer === '') {
                    this.isInWarranty = false;
                    this.group.controls.value.setValue(this.isInWarranty);
                    this.setFormStatus();
                    return new Observable<WarrantyCheckResult>();
                }
                return this.serviceHistoryService.checkVehicleWarrantyStatus$(this.createWarrantyCheckRequest()).pipe(
                    tap(result => {
                        this.isInWarranty = result.isInWarranty;
                        this.requiresRunningInService = result.requiresRunningInService;
                        if (!this.requiresRunningInService) {
                            this.runningInService.controls.value.setValue(null);
                        }
                        this.group.controls.value.setValue(result.isInWarranty);
                        if (this.isInWarranty) {
                            this.mileageBetweenServices.controls.value.setValidators(Validators.required);
                            this.monthsBetweenServices.controls.value.setValidators(Validators.required);
                            this.showFirstServiceNotDue = result.firstServiceNotDue || !result.serviceIntervalMonths || this.isFirstServiceNotDue;
                            if (!this.hasPreviousAnswers()) {
                                this.mileageBetweenServices.controls.value.setValue(result.serviceIntervalMileage);
                                this.monthsBetweenServices.controls.value.setValue(result.serviceIntervalMonths);
                                this.setFirstServiceNotDue(result.firstServiceNotDue);
                            }
                            const currentMax = parseInt(this.mileageBetweenServices.controls.maxValue.value, 10);
                            if (result.serviceIntervalMileage > currentMax) {
                                this.mileageBetweenServices.controls.maxValue.setValue(result.serviceIntervalMileage);
                            }
                        } else if (!this.hasPreviousAnswers()) {
                            this.setFirstServiceNotDue(false);
                        }

                        this.manufacturerWarrantyMileage = result.manufacturerWarrantyMileage;
                        this.manufacturerWarrantyAge = result.manufacturerWarrantyYears;
                        this.displayWarrantyStatus();
                        this.setFormStatus();
                        this.loadingError$.next(false);
                        this.loadingService.loading.emit(false);
                        this.warrantyCheckError.next(false);
                    }),
                    catchError(err => {
                        this.loadingError$.next(true);
                        this.loadingService.loading.emit(false);
                        this.warrantyCheckError.next(true);
                        this.notifications.dangerToast('An error occurred when checking warranty status', err);
                        return EMPTY;
                    }),
                    takeUntil(this.retryButtonPressedOrComponentDestroyed$)
                );
            }),
            catchError(err => {
                this.loadingError$.next(true);
                this.loadingService.loading.emit(false);
                this.vehicleCheckError.next(true);
                this.notifications.dangerToast('An error occurred while finding vehicle. Please try again.', err);
                return EMPTY;
            }),
            takeUntil(this.retryButtonPressedOrComponentDestroyed$)
        );
    }

    displayWarrantyStatus() {
        if (this.isInWarranty) {
            this.isVehicleDetailsCollapsed = false;
            this.warrantyStatusMessage = 'In Warranty';
        } else {
            this.isVehicleDetailsCollapsed = true;
            this.warrantyStatusMessage = 'Out of Warranty';
        }
    }

    createWarrantyCheckRequest() {
        const warrantyCheckRequest = new WarrantyCheckRequest();
        warrantyCheckRequest.manufacturer = this.manufacturer;
        warrantyCheckRequest.quoteStateId = this.quoteStateId;
        warrantyCheckRequest.vehicleMileage = this.mileage;
        warrantyCheckRequest.firstRegisteredOrManufacturedDate = this.minDate;
        return warrantyCheckRequest;
    }

    numberValueChanged(group: UntypedFormGroup) {
        if (group.controls) {
            if (group.controls.value.value !== null && group.controls.value.value < 1) {
                group.controls.value.setErrors({ 'incorrect': true });
            }
            this.updateServiceRecordsPhotos();
            this.setFormStatus();
        }
    }

    valueChanged(group: UntypedFormGroup) {
        if (group.controls) {
            this.updateServiceRecordsPhotos();
            this.setFormStatus();
        }
    }
}
