<div class="form-group" [formGroup]="group" [hidden]="this.isHidden">
  <label class="question-label" [attr.for]="identifier">{{ label }}</label>
  <div class="input-group">
    <input [readonly]="this.isInputDisabled" [attr.id]="identifier" class="form-control input-text obfuscated-playback"
      [ngClass]="{ 'prefilled-answer' : isPreFilledAnswer, 'no-question' : !label, 'warning': warningBorder, 'obfuscated-billing-details': encrypted }"
      [type]="keyboardType" minlength="{{ minLength }}" maxlength="{{ maxLength }}" (change)="valueChanged()" formControlName="value" [attr.inputmode]="inputMode"
      autocomplete="off" autocorrect="off" autocapitalize="{{ isAutocapitalized }}" mask="{{ mask }}" [showMaskTyped]="showMaskTyped" [attr.placeholder]="explanation" (change)="transformInput($event)" (keydown)="keypress($event)" />
    <span [ngClass]="mask ? 'placeholder-top' : 'placeholder'">{{ explanation }}</span>
    <span *ngIf="!mask" class="placeholder-line"></span>
  </div>
  <div *ngIf="showWarning" class='warning-label'>{{ warningMessage }}</div>
</div>
