import { Component, OnInit, Input } from '@angular/core';
import { BaseInputComponent } from '../base-input/base-input.component';
import { KeyValuePair } from '../../models/key-value-pair.model';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-multi-select-input',
  templateUrl: './multi-select-input.component.html',
  styleUrls: ['../input.component.scss'],
})
export class MultiSelectInputComponent extends BaseInputComponent implements OnInit {
  @Input() options: Array<KeyValuePair>;
  @Input() questionForm: UntypedFormGroup;
  @Input() halfWidth: boolean;

  markInvalid: boolean;
  defaultAnswer: string;
  optionIcon: string;
  disabled = false;
  readOnly = false;
  optionControls: Array<UntypedFormGroup>;
  checkCount = 0;

  ngOnInit() {
    super.ngOnInit();
    this.markInvalid = false;
    this.defaultAnswer = this.group.value.value;
    this.readOnly = this.group.value.readOnly ?? false;
    this.setOptionControls();
  }

  setOptionControls() {
    this.checkCount = 0;
    this.optionControls = new Array<UntypedFormGroup>(this.options.length);
    const controlArray = this.questionForm.get('items') as UntypedFormArray;
    const prefix = this.identifier;
    for (let i = 0; i < this.options.length; i+=1) {
      const question = `${prefix}-${this.options[i].key}`;
      const optCtrl = controlArray.controls.find((x) => (x as UntypedFormGroup).controls.name.value === question) as UntypedFormGroup;
      if (!optCtrl.value.value) {
        optCtrl.patchValue({
          value: '0'
        });
      }
      this.optionControls[i] = optCtrl;
      if (this.isChecked(i)) {
        this.checkCount += 1;
      }
    }
    this.updateOverallValue();
  }

  checkValid() {
    if (this.group.controls.value.value) {
      this.markInvalid = this.isTouchedAndInvalid(this.group.controls.value);
    }
  }

  checkFormControl(parameterName: string) {
    return this.group.value.name === parameterName;
  }

  removeInvalidMark() {
    this.markInvalid = false;
  }

  valueChanged(index: number) {
    if (this.optionControls[index]) {
    if (this.isChecked(index)) {
        this.optionControls[index].patchValue({
          value: '0'
        });
        this.checkCount -= 1;
      } else {
        this.optionControls[index].patchValue({
          value: '1'
        });
        this.checkCount += 1;
      }
    }
    this.updateOverallValue();
    this.change.emit(this.group);
  }

  updateOverallValue() {
    if (this.checkCount > 0) {
      this.group.patchValue({
        value: '1'
      });
    } else {
      this.group.patchValue({
        value: null
      });
    }
  }

  informedTrue(index: number): boolean {
    if (this.optionControls[index]) {
      return this.optionControls[index].value.informedAnswer === '1';
    } else {
      return false;
    }
  }

  defaultTrue(index: number): boolean {
    if (this.optionControls[index]) {
      return this.optionControls[index].value.defaultAnswer === '1';
    } else {
      return false;
    }
  }

  isChecked(index: number): boolean {
    if (this.optionControls[index]) {
      return this.optionControls[index].value.value === '1';
    } else {
      return false;
    }
  }
}
