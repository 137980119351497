import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { EMPTY } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { NotificationService } from '../services/notification.service';
import { PathFinderService } from '../services/path-finder.service';
import { CompletedState } from '../user-input/models/completed-state.model';

@Component({
  selector: 'app-purchase-menu',
  templateUrl: './purchase-menu.component.html',
  styleUrls: ['./purchase-menu.component.scss']
})
export class PurchaseMenuComponent {
  constructor(private pathFinder: PathFinderService, private notifications: NotificationService) { }
  private _quoteStateId: number;
  private _currentState: string;
  @ViewChild('currentItem') currentItem: ElementRef;
  @Input() set quoteStateId(value: number) {
    if (this._quoteStateId !== value) {
      this._currentState = null;
    }
    this._quoteStateId = value;
  }
  @Input() canGoBack: boolean;
  @Input() parentBusy: boolean;
  @Output() startRollback = new EventEmitter<boolean>();
  @Output() stateReturn = new EventEmitter<string>();

  _completedStates: Array<string>;
  isProcessing: boolean;

  @Input() set currentState(value: string) {
    if (this._currentState !== value) {
      if (this._completedStates && this._currentState) {
        this._completedStates.push(this._currentState);
      }
      this._currentState = value;
      this.scrollToCurrent();
    }
  }

  get completedStates(): Array<CompletedState> {
    return this.pathFinder.getCompletedStates(this._quoteStateId);
  }

  get currentState(): string {
    return this._currentState;
  }

  scrollToCurrent() {
    setTimeout(() => {
      if (this.currentItem?.nativeElement) {
        this.currentItem.nativeElement.scrollIntoView({ behavior: 'smooth' });
      } else {
        this.scrollToCurrent();
      }
    }, 500);
  }

  rollback(state: CompletedState) {
    if (state.canGoBack && !this.isProcessing && !this.parentBusy) {
      this.isProcessing = true;
      this.startRollback.emit(true);
      this.pathFinder.rollback$(this._quoteStateId, state.stateName, state.purchaseSection).pipe(
        tap(() => {
          this._currentState = null;
          this.stateReturn.emit(state.stateName);
          this.isProcessing = false;
        }),
        catchError(err => {
          this.notifications.dangerToast(`Failed to go back to ${state.stateName}`, err);
          this.stateReturn.emit(this.pathFinder.reset().name);
          this.isProcessing = false;
          return EMPTY;
        }),
        take(1)
      ).subscribe();
    }
  }
}
