import { Component, ElementRef, Input, OnInit, ViewChild, } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EMPTY, interval, Observable } from 'rxjs';
import { NotificationService } from '../../services/notification.service';
import { ImageService } from '../../services/image.service';
import { ImageToSave } from '../models/image-to-save.model';
import { catchError, delay, take, tap } from 'rxjs/operators';
import { BaseComponentDirective } from '../../base/base.component';
import { PathFinderService } from '../../services/path-finder.service';
import { LookupService } from '../../services/lookup.service';

@Component({
  selector: 'app-video-input',
  templateUrl: './video-input.component.html',
  styleUrls: ['./video-input.component.scss'],
})
export class VideoInputComponent
  extends BaseComponentDirective
  implements OnInit {
  @ViewChild('cameraInput', { read: ElementRef, static: true }) cameraInput: ElementRef;
  @Input() group: UntypedFormGroup;
  @Input() quoteStateId: number;

  label: string;
  video: ImageToSave;
  minDuration: number;
  maxDuration: number;
  inProgress: boolean;
  isLoading: boolean;
  showWarning = false;
  warningMessage: string;
  showProgressBar = false;
  currentUploadProgress: number;
  uploadAttempts: number;
  timer: Observable<number>; // 10 mins

  constructor(
    public imageService: ImageService,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer,
    private notifications: NotificationService,
    private pathFinder: PathFinderService,
    private lookupService: LookupService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.label = this.group.controls.question.value;
    this.video = this.group.controls.images.value[0];
    const minValue = this.group.controls.minValue.value;
    if (minValue) {
      this.minDuration = parseInt(minValue, 10);
    }
    const maxValue = this.group.controls.maxValue.value;
    if (maxValue) {
      this.maxDuration = parseInt(maxValue, 10);
    }
    this.warningMessage = this.group.controls.warning.value.message;

    this.pathFinder.registerTransitionEvent(onComplete => {
      this.performVideoSubmission(onComplete);
    });

    const videoUploadTimeBeforeFailureInMins = this.pathFinder.getPurchaseSettingAsNumber('VideoUploadTimeBeforeFailureInMins');
    this.timer = interval(videoUploadTimeBeforeFailureInMins * 60000);

  }

  async performVideoSubmission(onComplete: (performTransition: boolean) => void) {
    const chunkSize = 2097152;
    let number = 1;
    let failed = false;
    let hasFinished = false;

    const obs = this.timer.subscribe(() => {
      if (!hasFinished) {
        this.showProgressBar = false;
        onComplete(false);
        failed = true;
        const err = { message: "Upload timed out" };
        this.recordVideoSubmissionFailure(onComplete, err);
        this.imageService.cancelVideoUpload$(this.quoteStateId).subscribe();
        obs.unsubscribe();
      }
    })

    this.showProgressBar = true;
    for (let offset = 0; offset < this.video.image.size; offset += chunkSize) {
      const chunk = this.video.image.slice(offset, offset + chunkSize);
      if (offset === 0) {
        this.currentUploadProgress = 0;
      } else {
        this.currentUploadProgress = Math.round((offset / this.video.image.size) * 100) / 2; // progress bar at 50% 
      }
      await this.imageService.submitVideoChunk$(this.quoteStateId, chunk, number).toPromise().catch((error) => {
        failed = true;
        // Quit at the first failure
        offset = this.video.image.size;
        this.recordVideoSubmissionFailure(onComplete, error);
      });
      number += 1;
    }
    if (!failed) {
      this.imageService.completeVideoUpload$(this.quoteStateId).pipe(
        tap(() => {
          this.currentUploadProgress = 100; // complete the progress bar      
          hasFinished = true;
        }),
        delay(500),
        tap(() => {
          onComplete(true);
        }),
        catchError(err => {
          onComplete(false);
          this.recordVideoSubmissionFailure(onComplete, err);
          return EMPTY;
        }),
        take(1)
      ).subscribe();
    } else {
      this.uploadAttempts++;
    }
  }

  recordVideoSubmissionFailure(onComplete: (performTransition: boolean) => void, error) {
    this.imageService.recordVideoSubmissionFailure$(this.quoteStateId).pipe(
      tap(retry => {
        if (retry) {
          this.notifications.dangerToast('Failed to save video', error);
          onComplete(false);
        } else {
          this.notifications.warningToast('Failed to save video - continuing with purchase');
          onComplete(true);
        }
      }),
      catchError(err => {
        this.notifications.dangerToast('Failed to log video attempt', err);
        onComplete(false);
        return EMPTY;
      }),
      take(1)
    ).subscribe();
  }

  cancelFileUpload() {
    this.inProgress = false;
  }

  onFileSelected(event) {
    const target = event.target || event.srcElement;
    this.video.image = target.files[0];
    this.video.thumbnailUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(target.files[0]));
    this.inProgress = false;

    const element = document.createElement('video');
    element.preload = 'metadata';
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const scope = this;
    element.onloadedmetadata = function() {
      scope.validateDuration(element.duration);
    };
    element.src = URL.createObjectURL(target.files[0]);
  }

  validateDuration(duration: number) {
    this.showWarning = (this.minDuration && duration < this.minDuration) || (this.maxDuration && duration > this.maxDuration);
    if (this.showWarning) {
      this.group.controls.value.setValue(null);
    } else {
      this.group.controls.value.setValue(true);
    }
  }

  get isTaken() {
    return this.video.thumbnailUrl && this.video.thumbnailUrl !== null;
  }

  openCamera() {
    if (!this.inProgress) {
      this.inProgress = true;
      this.cameraInput.nativeElement.click();
    }
  }

  deleteVideo() {
    this.showWarning = false;
    this.video.image = null;
    this.video.thumbnailUrl = null;
    this.group.controls.value.setValue(null);
  }

  showPreview(content) {
    this.modalService.open(content, { size: 'lg' });
  }
}
