import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { EMPTY, Observable, Subject, Subscription } from 'rxjs';
import { catchError, take, takeUntil, tap } from 'rxjs/operators';
import { ConfirmDialogService } from '../confirm-dialog/services/confirm-dialog.service';
import { AuthService } from '../core/auth-service';
import { DashMileageModalComponent } from '../dash-mileage-modal/dash-mileage-modal.component';
import { NotificationService } from '../services/notification.service';
import { PathFinderService } from '../services/path-finder.service';
import { PurchaseService } from '../services/purchase.service';
import { TaskService } from '../services/task.service';
import { Appointment } from './models/appointment.model';
import { Note } from './models/note.model';
import { ImageTypes } from '../enums/image-types';
import { TaskTypes } from '../task-list/enums/task-types';
import moment from 'moment';
import { ModalSelectComponent } from '../modal-select/modal-select.component';
import { BookAppointmentService } from '../book-appointment/services/book-appointment.service';
import { PostcodeLookupService } from '../postcode-lookup/services/postcode-lookup.service';
import { WalkInLookupData } from '../walk-in/models/walk-in-lookup-data.model';
import { WalkInComponent } from '../walk-in/walk-in.component';
import { LookupService } from '../services/lookup.service';
import { VehicleListValue } from '../walk-in/models/vehicle-list-value.model';
import { VehicleIdentification } from '../walk-in/models/vehicle-identification.model';
import { NavigationHistoryService } from '../core/navigation-history.service';
import { AltVrmComponent } from '../alt-vrm/alt-vrm.component';
import { VehicleLookupStatusTypes } from '../enums/vehicle-lookup-status-types';
import { HpiRetryComponent } from '../vehicle-check-hpi/hpi-retry/hpi-retry.component';
import { VrmConfirmationModalComponent } from '../vrm-confirmation-modal/vrm-confirmation-modal.component';
import { ConfirmDialogImageTypes } from '../confirm-dialog/enums/confirm-dialog-image-types';
import { Buyer } from '../models/buyer.model';
import { AppointmentService } from '../services/appointment.service';
import { BuyerService } from '../services/buyer.service';
import { HpiModalComponent } from '../hpi-modal/hpi-modal.component';
import { LookupErrorComponent } from '../search-vrm/lookup-error/lookup-error.component';
import { AppointmentOutcomeTypes } from '../enums/appointment-outcome-types';

@Component({
  selector: 'app-appointment-details',
  templateUrl: './appointment-details.component.html',
  styleUrls: ['./appointment-details.component.scss'],
})
export class AppointmentDetailsComponent implements OnInit, OnDestroy {

  constructor(private modal: NgbActiveModal, private router: Router, private purchaseService: PurchaseService,
    private notifications: NotificationService, private pathFinder: PathFinderService, private modalService: NgbModal,
    private authService: AuthService, private confirmService: ConfirmDialogService,
    private taskService: TaskService,
    private bookAppointmentService: BookAppointmentService, private postcodeLookupService: PostcodeLookupService,
    private lookupService: LookupService,
    private navigationHistory: NavigationHistoryService,
    private buyerService: BuyerService,
    private appointmentService: AppointmentService) { }

  @Input() appointmentId: number;
  @Input() appointmentDuration: number;
  @Input() taskId: number;
  @Input() taskType: TaskTypes;
  @Input() hideActions: boolean;

  appointment: Appointment;
  canContinue: boolean;
  username: string;
  isAddingNote: boolean;
  isSavingNote: boolean;
  note = new UntypedFormControl('');
  isCompleting: boolean;
  stolen: string;
  finance: string;
  collision: string;
  hasSalvage: string;
  rkc: string;
  hasHpiData: boolean;
  isVehiclePurchased: boolean;
  vehicleIdentification: VehicleIdentification;
  vehicleDescriptions: VehicleListValue[];
  isRescheduling: boolean;
  loading: boolean;
  outcomeLabel = 'Outcome';
  buyer: Buyer;
  isAssignedToThisBuyer: boolean;
  @ViewChild('noteInputBox') noteInputRef: ElementRef;
  appointmentStatus: string;

  destroy$ = new Subject();

  noteConfirmationText = 'Would you like to\nsave your note?';

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.buyer = this.buyerService.getBuyerFromStorage();
    this.loading = true;
    this.navigationHistory.setId(null);
    this.appointment = new Appointment();
    this.username = this.authService.getUser()?.name;
    this.hasHpiData = true;
    this.purchaseService
      .getAppointmentDetail$(this.appointmentId)
      .pipe(
        tap((result) => {
          this.showAppointment(result);
        }),
        catchError((err) => {
          this.dismissModal();
          this.notifications.dangerToast(
            'Unable to view appointment details, this appointment may have been rescheduled',
            err
          );
          return EMPTY;
        }),
        take(1)
      )
      .subscribe();
  }

  showAppointment(appointment: Appointment) {
    if (appointment) {
      this.appointment = appointment;
      this.isVehiclePurchased = appointment.isVehiclePurchased;
      this.stolen = this.booleanIcon(appointment.stolen || appointment.hasTheftMaker);
      this.finance = this.booleanIcon(appointment.finance);
      this.collision = this.booleanIcon(appointment.condition);
      this.hasSalvage = this.booleanIcon(appointment.hasSalvage);
      this.isAssignedToThisBuyer = this.appointment.assignedTo.toLocaleLowerCase() === this.buyer.employee.userName.toLocaleLowerCase();
      if (appointment.vehicleCheckId !== null && appointment.vehicleCheckId !== 0) {
        this.hasHpiData = true;
      } else {
        this.hasHpiData = false;
      }
      const isRkc = this.pathFinder.isRecentKeeperChange(
        null,
        appointment.answers,
        appointment.vehiclePriceOffered
      );
      this.rkc = this.booleanIcon(isRkc);
      if (this.appointment.isLate) {
        this.outcomeLabel = 'Late';
      }
      if (
        !appointment.isEnded &&
        appointment.completedStates &&
        appointment.completedStates.length > 0
      ) {
        const state = this.pathFinder.findState(
          appointment.completedStates.pop()
        );
        if (state) {
          this.canContinue =
            state.transitions != null && state.transitions.length > 0;
        } else {
          this.canContinue = false;
        }
      } else {
        this.canContinue = !appointment.isStarted || (appointment.isStarted && !appointment.isEnded);
      }
      this.loading = false;
    } else {
      this.dismissModal();
      this.notifications.dangerToast(
        'Unable to view appointment details, this appointment may have been rescheduled',
        null
      );
    }
  }

  get hasAppointmentEndTimePassed(): boolean {
    const endTime = moment(this.appointment.appointmentTime).add(this.appointmentDuration, 'm');
    return endTime.isBefore(new Date());
  }

  get hasAppointmentStartTimePassed(): boolean {
    return moment(this.appointment.appointmentTime).isBefore(new Date());
  }

  canSetToLate(): boolean {
    if (this.appointment.isLate || this.appointment.isStarted || this.appointment.isEnded || !this.canContinue) {
      return false;
    }
    return this.hasAppointmentStartTimePassed;
  }

  canSetToNoShow(): boolean {
    if (this.appointment.isStarted || this.appointment.isEnded || !this.canContinue) {
      return false;
    }
    return this.hasAppointmentStartTimePassed;
  }

  dismissModal() {
    if (this.isAddingNote) {
      this.confirmService.showYesNoConfirmation(this.noteConfirmationText, 
        () => {
          this.saveNote(() => {
            this.modal.dismiss();
          });
        }, () => {
          this.modal.dismiss();
        });
    } else {
      this.modal.dismiss();
    }
  }

  continuePurchase() {
    if (this.isAddingNote) {
      this.confirmService.showYesNoConfirmation(this.noteConfirmationText, 
        () => {
          this.saveNote(() => {
            this.actuallyContinuePurchase();
          });
        }, () => {
          this.actuallyContinuePurchase();
        });
    } else {
      this.actuallyContinuePurchase();
    }
  }

  actuallyContinuePurchase() {
    if ((!this.appointment.isStarted && !this.appointment.isEnded) || this.canContinue) {
      if (this.appointment.isStarted) {
        this.router.navigate(['/purchase', this.appointment.quoteStateId]);
        this.modal.close();
      } else if (this.appointment.noHpiData && this.appointment.hpiStatusId === VehicleLookupStatusTypes.Success) {
        this.modal.close();
        this.openAltVrmComponentModal();
      } else if (this.appointment.noHpiData  && this.appointment.hpiStatusId !== VehicleLookupStatusTypes.Success) {
        this.retry();
      } else if (this.appointment.valuation.requiresManualValuation) {
        this.modal.close();
        this.openErrorComponentModal();
      } else if (!this.appointment.noHpiData) {
        this.modal.close();
        this.openVrmConfirmationComponentModal();
      }
    }
  }

  async claimAppointment() {
    const confirm = await this.confirmService.showYesNoConfirmationAsync('Are you sure you would like to claim this appointment?');
    if (confirm) {
      this.appointmentService
        .claimAppointment$(this.appointmentId, this.buyer.employee.userName)
        .pipe(
          tap((appointment) => {
            this.appointmentService.appointment.emit(appointment);
            this.dismissModal();
          }),
          catchError((err) => {
            this.dismissModal();
            this.unableToClaimModal(err.error)
            return EMPTY;
          }),
          take(1)
        )
        .subscribe();
    }
  }


  unableToClaimModal (message: string) {
    const modalRef = this.modalService.open(HpiModalComponent, {
      keyboard: false,
      backdrop: 'static',
      centered: true,
      size: 'md',
    });

    modalRef.componentInstance.isYesNo = false;
    modalRef.componentInstance.isOk = true;
    modalRef.componentInstance.isYes = false;
    modalRef.componentInstance.title = 'Unable to claim';
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.displayImageUrl =  ConfirmDialogImageTypes.warningUrl;

    modalRef.result.then(
      () => { this.dismissModal();},
      () => { }
    );
  }

  openAltVrmComponentModal() {
    const modalRef = this.modalService.open(AltVrmComponent, {
      keyboard: false,
      backdrop: 'static',
      centered: true,
    });
    modalRef.componentInstance.vrm = this.appointment.vrm;
    modalRef.componentInstance.quoteStateId = this.appointment.quoteStateId;
    modalRef.componentInstance.bookedWithNoHpi = true;
    modalRef.result.then(
      () => {
        this.modal.close();
        this.openDashMileageComponentModal();
      },
      () => { 
      }
    );
  }

  openVrmConfirmationComponentModal() {
    const modalRef = this.modalService.open(VrmConfirmationModalComponent, {
      keyboard: false,
      backdrop: 'static',
      centered: true,
      size: 'md',
    });
    modalRef.componentInstance.appointmentVrm = this.appointment.vrm;
    modalRef.componentInstance.displayImageUrl = ConfirmDialogImageTypes.warningUrl;
    modalRef.componentInstance.quoteStateId = this.appointment.quoteStateId;
    modalRef.componentInstance.vrmMatches.pipe(
      tap(matches => {
        if (matches === true) {
          this.openDashMileageComponentModal();
        }
      }),
      take(1))
      .subscribe(() => {
      });

    modalRef.result.then(
      () => { },
      () => { }
    );
  }

  openDashMileageComponentModal() {
    const modalRef = this.modalService.open(DashMileageModalComponent, {
      keyboard: false,
      backdrop: 'static',
      centered: true,
      size: 'md',
    });
    modalRef.componentInstance.vrm = this.appointment.vrm;
    modalRef.componentInstance.quoteStateId = this.appointment.quoteStateId;
    modalRef.result.then(
      () => { },
      () => { }
    );
  }

  openErrorComponentModal() {
    const modalRef = this.modalService.open(LookupErrorComponent, {
      keyboard: false,
      backdrop: 'static',
      centered: true,
      size: 'md',
    });
    modalRef.componentInstance.requiresManualValuation = true;
    modalRef.result.then(
      () => { },
      () => { }
    );
  }

  retry() {
      const modalRef = this.modalService.open(HpiRetryComponent, {
        keyboard: false,
        backdrop: 'static',
        centered: true,
        size: 'lg',
      });
      modalRef.componentInstance.vrm = this.appointment.vrm;
      modalRef.componentInstance.appointmentId = 0;
      modalRef.componentInstance.thingToRetry$ = this.lookupService.findVehicle$(this.appointment.vrm);
      modalRef.componentInstance.hpiSuccess.subscribe(() => {
        this.modal.close();
        this.openDashMileageComponentModal();
      });
  }

  get engineFormat() {
    return `${this.appointment.engineSize}cc`;
  }

  onNoteInput() {
    this.resizeNoteBox(false);
    this.isAddingNote = this.note.value.length > 0;
  }

  cancelNote() {
    this.note.setValue('');
    this.resizeNoteBox(true);
    this.isAddingNote = false;
  }

  resizeNoteBox(reset: boolean) {
    const minHeight = 36;
    const maxHeight = 240;

    let height = Number(this.noteInputRef.nativeElement.scrollHeight);
    if (height < minHeight || reset) {
      height = minHeight;
    } else if (height > maxHeight) {
      height = maxHeight;
    }

    this.noteInputRef.nativeElement.style.height = '0';
    this.noteInputRef.nativeElement.style.height = `${height}px`;
  }

  saveNote(onComplete: () => void) {
    if (this.isSavingNote) {
      return;
    }

    this.isSavingNote = true;
    const noteText = this.note.value;
    this.purchaseService
      .addNote$(this.appointment.quoteStateId, noteText)
      .pipe(
        tap(() => {
          this.isSavingNote = false;
          this.appointment.notes.unshift(new Note(this.username, noteText));
          this.cancelNote();
          if (onComplete) {
            onComplete();
          }
        }),
        catchError((err) => {
          this.isSavingNote = false;
          this.notifications.dangerToast('Failed to save note', err);
          return EMPTY;
        }),
        take(1)
      )
      .subscribe();
  }

  formatForDisplay(text: string): string {
    return text.replace(/\n/g, '<br>');
  }

  get isTaskAction(): boolean {
    if (this.taskId) {
      return true;
    } else {
      return false;
    }
  }

  get isCustomerContactTask(): boolean {
    if (this.taskId && this.taskType === TaskTypes.CustomerContact) {
      return true;
    } else {
      return false;
    }
  }

  get isNoShowTask(): boolean {
    let canBegin = true;
    if (!this.appointment.isStarted) {
      const isYesterdayAppt = moment(this.appointment.appointmentTime).isBefore(new Date(), 'day');
      const isAfterStart = new Date().getTime() >= moment(this.appointment.canStartTime).toDate().getTime();
      canBegin = isAfterStart && !isYesterdayAppt;
    }
    if (this.taskId) {
      if (this.taskType === TaskTypes.NoShow) {
        return true;
      } else {
        return false
      }
    } else {
      if (this.appointmentStatus != null
        && (this.appointmentStatus === TaskTypes.NoShow
          || this.appointmentStatus === AppointmentOutcomeTypes.VehiclePurchased
          || this.appointmentStatus === AppointmentOutcomeTypes.VehicleNotPurchased
        || this.appointmentStatus === AppointmentOutcomeTypes.PurchaseStarted)
        || !canBegin) {
        return false;
      } else {
        return true
      }
    }
  }

  get isNotPurchasedTask(): boolean {
    if (this.taskId && this.taskType === TaskTypes.AppointmentNotPurchased) {
      return true;
    } else {
      return false;
    }
  }

  get isConfirmCallTask(): boolean {
    if (this.taskId && this.taskType === TaskTypes.ConfirmCall) {
      return true;
    } else {
      return false;
    }
  }

  completeTask() {
    if (this.isAddingNote) {
      this.confirmService.showYesNoConfirmation(this.noteConfirmationText, 
        () => {
          this.saveNote(() => {
            this.actuallyCompleteTask();
          });
        }, () => {
          this.actuallyCompleteTask();
        });
    } else {
      this.actuallyCompleteTask();
    }
  }

  actuallyCompleteTask() {
    this.isCompleting = true;
    if (this.taskType === TaskTypes.ConfirmCall) {
      this.taskService.confirm$(this.appointmentId)
        .pipe(
          tap(() => {
            this.isCompleting = false;
            this.modal.close();
          }),
          catchError((err) => {
            this.notifications.dangerToast('Failed to complete task', err);
            this.isCompleting = false;
            return EMPTY;
          }),
          take(1)
        ).subscribe();
    } else if (this.taskType === TaskTypes.NoShow) {
      this.taskService.noShow$(this.appointmentId)
        .pipe(
          tap(() => {
            this.isCompleting = false;
            this.modal.close();
          }),
          catchError((err) => {
            this.notifications.dangerToast('Failed to complete task', err);
            this.isCompleting = false;
            return EMPTY;
          }),
          take(1)
        ).subscribe();
    } else if (this.taskType === TaskTypes.CustomerContact) {
      this.taskService.customerContact$(this.appointmentId)
        .pipe(
          tap(() => {
            this.isCompleting = false;
            this.modal.close();
          }),
          catchError((err) => {
            this.notifications.dangerToast('Failed to complete task', err);
            this.isCompleting = false;
            return EMPTY;
          }),
          take(1)
        ).subscribe();
    } else if (this.taskType === TaskTypes.AppointmentCancellation) {
      this.taskService.appointmentCancellation$(this.appointmentId)
        .pipe(
          tap(() => {
            this.isCompleting = false;
            this.modal.close();
          }),
          catchError((err) => {
            this.notifications.dangerToast('Failed to complete task', err);
            this.isCompleting = false;
            return EMPTY;
          }),
          take(1)
        ).subscribe();
    } else if (this.taskType === TaskTypes.AppointmentNotPurchased) {
      this.taskService.notPurchasedAppointment$(this.appointmentId)
        .pipe(
          tap(() => {
            this.isCompleting = false;
            this.modal.close();
          }),
          catchError((err) => {
            this.notifications.dangerToast('Failed to complete task', err);
            this.isCompleting = false;
            return EMPTY;
          }),
          take(1)
        ).subscribe();
    } else {
      this.isCompleting = false;
    }
  }

  changeOutcome(action: Observable<Note>, errorMessage: string, onSuccess: () => void) {
    action.pipe(
      tap(note => {
        this.isCompleting = false;
        note.new = true;
        this.appointment.notes.unshift(note);
        onSuccess();
      }),
      catchError((err) => {
        this.notifications.dangerToast(errorMessage, err);
        this.isCompleting = false;
        return EMPTY;
      }),
      take(1)
    ).subscribe();
  }

  customerLate() {
    this.isCompleting = true;
    this.confirmService.showYesNoConfirmation('Are you sure you would\nlike to flag this\nappointment as late?', 
      () => {
        this.changeOutcome(this.taskService.late$(this.appointmentId), 'Failed to mark customer as late', () => {
          this.appointment.isLate = true;
          this.outcomeLabel = 'Late';
        });
      }, () => {
        this.isCompleting = false;
      });
  }

  noShow() {
    this.isCompleting = true;
    this.confirmService.showYesNoConfirmation('Are you sure you would\nlike to flag this\nappointment as a no show?',
      () => {
        this.changeOutcome(this.taskService.noShow$(this.appointmentId), 'Failed to set appointment as a no show', () => {
          this.appointment.canReschedule = false;
          this.appointmentStatus = AppointmentOutcomeTypes.NoShow;
          this.canContinue = false;
          this.outcomeLabel = 'No Show';
        });
      }, () => {
        this.isCompleting = false;
      });
  }

  get isShowingOutcomePicker(): boolean {
    return !this.isTaskAction && (this.canSetToLate() || this.canSetToNoShow());
  }

  updateOutcome() {
    const lateOption = 'Customer Late';
    const noShowOption = 'No Show';

    const modalRef = this.modalService.open(ModalSelectComponent, {
      keyboard: false,
      centered: true
    });
    const options = new Array<string>();
    if (this.canSetToLate()) {
      options.push(lateOption);
    }
    if (this.canSetToNoShow()) {
      options.push(noShowOption);
    }
    if (options.length > 0) {
      modalRef.componentInstance.options = options;
      modalRef.result.then(
        (result) => {
          if (result === lateOption) {
            this.customerLate();
          } else if (result === noShowOption) {
            this.noShow();
          }
        },
        () => { }
      );
    }
  }

  rescheduleAppointment() {
    this.isRescheduling = true;
    this.startBookingAppointment(this.appointment.postcode, false);
  }

  startBookingAppointment(postcode: string, isNewPostcodeLookup: boolean) {
    const lookupData = new WalkInLookupData(this.appointment.quoteId, this.appointment.quoteGuid, this.appointment.vrm,
      this.appointment.mileage, null, null, false, null, this.appointment.customerName, this.appointment.postcode,
      this.appointment.customerEmail, this.appointment.customerPhone);
    lookupData.quoteStateId = this.appointment.quoteStateId;
    lookupData.valuationGuid = this.appointment.valuationGuid;

    const bookingAppointmentSubscription = this.bookAppointmentService.openBookAppointmentModal$(postcode, lookupData, this.appointment.bookId, true)
      .pipe(tap(result => {
        if (isNewPostcodeLookup) {
          this.postcodeLookupService.setNewPostcodeSearchCompleted();
        }
        this.listenToBookAppointmentOutput(result, bookingAppointmentSubscription);
      }),
        catchError(err => {
          this.notifications.dangerToast('Failed to start booking appointment', err);
          this.isRescheduling = false;
          return EMPTY;
        }),
        takeUntil(this.destroy$)
      ).subscribe();
  }

  listenToBookAppointmentOutput(bookAppointmentModalRef: NgbModalRef, bookingAppointmentSubscription: Subscription) {
    bookAppointmentModalRef.componentInstance.backToValuationQuestions
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.isRescheduling = false;
      });

    bookAppointmentModalRef.componentInstance.requiresManualValuation
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.isRescheduling = false;
      });

    bookAppointmentModalRef.componentInstance.newPostcode
      .pipe(takeUntil(this.destroy$))
      .subscribe(postcode => {
        bookingAppointmentSubscription.unsubscribe();
        this.startBookingAppointment(postcode, true);
      });
  }

  booleanIcon(value: boolean) {
    if (value) {
      return '../../assets/icons/buttons/trueTick.svg';
    } else if (value === false) {
      return '';
    } else {
      return '../../assets/icons/buttons/redDash.svg';
    }
  }

  uploadDocuments() {
    if (this.isVehiclePurchased || this.taskId) {
      const id = this.taskId ? this.taskId : this.appointmentId;
      this.navigationHistory.setId(id);
      this.router.navigate(
        ['/postPurchase/images', this.appointment.quoteStateId],
        { queryParams: { imageType: ImageTypes.ScannedDocument } }
      );
    }
    this.modal.close();
  }

  uploadVehicleImages() {
    if (this.isVehiclePurchased || this.taskId) {
      const id = this.taskId ? this.taskId : this.appointmentId;
      this.navigationHistory.setId(id);
      this.router.navigate(
        ['/postPurchase/images', this.appointment.quoteStateId],
        { queryParams: { imageType: ImageTypes.VehiclePhoto } }
      );
      this.modal.close();
    }
  }

  walkIn(isStartingPurchase: boolean) {
    this.lookupService.findVehicle$(this.appointment.vrm).pipe(
      tap(result => {
        this.vehicleIdentification = result;
        this.populateVehicleDescription();
        this.bookAppointmentService.taskDetails(this.appointmentId, this.taskType);
        const modalRef = this.redirectToModal(WalkInComponent);
        modalRef.componentInstance.isStartPurchase = isStartingPurchase;
        modalRef.componentInstance.vrm = this.appointment.vrm;
        modalRef.componentInstance.creatingNewValuation = true;
        modalRef.componentInstance.vehicleDescription = this.vehicleDescriptions[0];
        modalRef.componentInstance.mileage = this.appointment.mileage;
        modalRef.componentInstance.isQuickQuote = true;
        modalRef.componentInstance.hasDescription = true;
        modalRef.componentInstance.vehicleImage = this.vehicleDescriptions[0].ImageUrl;
        modalRef.componentInstance.defaultVehicleImageUrl = this.vehicleDescriptions[0].DefaultVehicleImageUrl;
        modalRef.componentInstance.newValuationCustomerDetails = true;
      }
      ),
      catchError(err => {
        this.notifications.dangerToast('Call to vehicle check failed.', err);
        return EMPTY;
      }),
      take(1)
    ).subscribe();
  }

  populateVehicleDescription() {
    this.vehicleDescriptions = new Array<VehicleListValue>();
    const hasSalvage = this.vehicleIdentification.hasSalvage;
    this.vehicleIdentification.vehicleLabelValueLists.forEach(
      vehicle => {
        const description = new VehicleListValue(vehicle, this.vehicleIdentification.quoteId, this.vehicleIdentification.quoteGuid, '', hasSalvage);
        this.vehicleDescriptions.push(description);
      }
    );
  }

  redirectToModal(component): NgbModalRef {
    return this.modalService.open(component, {
      keyboard: false,
      backdrop: 'static',
      centered: true,
    });
  }

  get isPurchasable() : boolean {
    const isFinished = this.appointment.isEnded || !this.canContinue;
    let canBegin = true;
    if (!this.appointment.isStarted) {
      const isYesterdayAppt = moment(this.appointment.appointmentTime).isBefore(new Date(), 'day');
      const isAfterStart = new Date().getTime() >= moment(this.appointment.canStartTime).toDate().getTime();
      canBegin = isAfterStart && !isYesterdayAppt;
    }
    return !isFinished && canBegin;
  }
}
