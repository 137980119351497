<div class="page-content page-content-after-header task-container">
  <div class="row">
    <div class="col-6 task-date">{{ currentTime | date: "d MMMM y HH:mm" }}</div>
    <div class="col-6 right task-sort">
      <div ngbDropdown #theDropdown="ngbDropdown" (openChange)="toggleSort()">
        <input readonly="readonly" class="task-dropdown" [ngClass]="sortOpened ? 'opened' : ''" placeholder="Sort By"
          autocomplete="off" autocorrect="off" ngbDropdownToggle />
        <div ngbDropdownMenu class="pre-scrollable">
          <button (click)="onSortChange(sortType);theDropdown.close();" *ngFor="let sortType of sortTypes"
            id="{{ sortType }}" ngbDropdownItem>{{ sortType }}</button>
        </div>
      </div>
    </div>
  </div>
  <table aria-label="Tasks for specific VRM">
    <tr class="task-grid-header">
      <th class="">Task Type</th>
      <th class="customer-name">Customer's Name</th>
      <th class="appointment">Date & Time of Appointment</th>
      <th class="">VRM</th>
      <th class="">Branch</th>
      <th class="">Buyer's Name</th>
      <th class="separator"></th>
      <th class="action"></th>
    </tr>
    <ng-container *ngIf="loading">
      <app-loading-wbac-animation></app-loading-wbac-animation>
    </ng-container>
    <ng-container *ngIf="!loading">
      <ng-container   *ngFor="let task of pagedTasks; let i = index">
        <tr class="task-grid-row">
          <td class="task-type" [ngClass]="getTaskClass(task)">{{ task.taskType }}</td>
          <td class="obfuscated-playback">{{ task.customerName }}</td>
          <td class="">{{ task.appointmentDate | date: "dd/MM/yyyy" }}<br>{{ task.appointmentDate | date: "HH:mm" }}</td>
          <td class="">{{ task.vrm }}</td>
          <td class="">{{ task.branch }}</td>
          <td class="assigned">{{ task.assignedTo }}</td>
          <td class="separator"></td>
          <td class="button-claim" (click)="claimTask(task)">
            Claim
            <img src="../../../assets/icons/tasks/refresh.svg" alt="">
          </td>
        </tr>
        <tr class="separator-row">
        </tr> 
      </ng-container>
      <tr *ngIf="!filteredTasks || filteredTasks.length === 0">
        <td colspan="8">
          <div class="no-task-message">
            <app-registration-plate [vrm]="vrm" class="mb-3"></app-registration-plate>
            There are currently no tasks<br>
            associated with this VRM
          </div>
        </td>
      <tr>
    </ng-container>
  </table>
  <div class="pager-container">
    <app-pager [pageCount]="pageCount" [currentPage]="currentPage"></app-pager>
  </div>
</div>