import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PagerService } from './services/pager.service';
import { catchError, takeUntil } from 'rxjs/operators';
import { EMPTY, Subject } from 'rxjs';

@Component({
  selector: 'app-pager',
  templateUrl: './pager.component.html',
  styleUrls: ['./pager.component.scss']
})
export class PagerComponent implements OnInit, OnDestroy {

  @Input() pageCount: number;

  @Input() currentPage = 1;

  destroy$ = new Subject();

  constructor(private pagerService: PagerService) { }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.pagerService.getPageNumber().pipe(
        takeUntil(this.destroy$),
        catchError(() => {
          return EMPTY;
        })
      )
      .subscribe((result) => {
        this.currentPage = result;
      });
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage -= 1;
      this.pagerService.setPageNumber(this.currentPage);
    }
  }

  nextPage() {
    if (this.currentPage < this.pageCount) {
      this.currentPage += 1;
      this.pagerService.setPageNumber(this.currentPage);
    }
  }

}
