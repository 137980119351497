import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-salvaged-records',
  templateUrl: './salvaged-records.component.html',
  styleUrls: ['./salvaged-records.component.scss']
})
export class SalvagedRecordsComponent implements OnInit {
  @Input() ancestryCategory: string;
  @Input() hasSalvage: boolean;
  constructor() { }

  ngOnInit(): void {
  }

}
