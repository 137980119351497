<div class="form-group" [formGroup]="group" [hidden]="this.isHidden">
    <label class="question-label multiselect-question" [attr.for]="identifier" *ngIf="label">{{ label }}</label>
    <label class="multiselect-label">&nbsp;(multiple can be selected):</label>
    <label class="question-label d-block" *ngIf="explanation">{{ explanation }}</label>
    <div [attr.id]="identifier" class="btn-group-toggle">
      <label ngbButtonLabel class="btn list-button multiple-selection"
            *ngFor="let option of options; let i = index"
            [ngClass]="{ 
              'invalid': markInvalid,
              'btn-block': vertical,
              'informed-answer': informedTrue(i), 
              'default-answer': defaultTrue(i),
              'active' : isChecked(i),
              'readonly': readOnly,
              'top-row': halfWidth && i < 3
              }">
        <input formControlName="value" type="checkbox" [value]="option.key" (blur)="checkValid()" (focus)="removeInvalidMark()" (change)="valueChanged(i)" [disabled]="disabled">
        <span [innerHTML]="option.value"></span>
      </label>
    </div>
  </div>
  