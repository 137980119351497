import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Page } from '../models/page.model';
import { KeyValuePair } from '../../models/key-value-pair.model';
import { Question } from '../models/question.model';
import { PathFinderService } from '../../services/path-finder.service';
import { VehicleDetails } from '../../models/vehicle-details';
import { DefaultAnswer } from '../models/default-answer.model';
import { ScreenControlTypes } from '../../enums/screen-control-types';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-question-column',
  templateUrl: './question-column.component.html',
  styleUrl: '../user-input.component.scss'
})
export class QuestionColumnComponent implements OnInit {
  @Input() quoteStateId: number;
  @Input() vrm: string;
  @Input() questionForm: UntypedFormGroup;
  @Input() column: number;
  @Input() questionPages: Page[];
  @Input() stateName: string;
  @Input() isAppraisalFinalised: boolean;
  @Input() vehicleDetails: VehicleDetails;
  @Input() updatedAnswers: Array<Question>;
  @Input() questionDefaults: Array<DefaultAnswer>;
  @Input() requiredImageType: number;
  @Input() answersSaved: boolean;
  @Input() saveNotifier: Subject<number>;
  @Input() isChangeVrmFlow: boolean;
  @Input() isAltVRMFlow: boolean;

  @Output() change = new EventEmitter<UntypedFormGroup>();

  defaultVehicleImage = '../../assets/images/unknownCar.png';
  mileage: number;
  hpiFirstRegistered: string;
  ancestryCategory: string;
  controlTypes = ScreenControlTypes;

  constructor(
    private pathFinder: PathFinderService,
  ) {
  }

  ngOnInit(): void {
    this.getDataFromAnswers();
  }

  getOptions(name: string): KeyValuePair[] {
    return this.findQuestionByName(name).options;
  }

  findQuestionByName(parameterName: string): Question {
    for (let i = 0; i < this.questionPages.length; i++) {
      const question = this.questionPages[i].questions.find(
        (x) => x.parameterName === parameterName
      );
      if (question) {
        return question;
      }
    }
    return null;
  }

  showItem(item: UntypedFormGroup): boolean {
    if (!this.isInColumn(item.controls.column.value)) {
      return false;
    }

    if (!item.controls.condition.value) {
      return true;
    }

    return this.pathFinder.evaluateCondition(item.controls.condition.value);
  }

  isInColumn(col: number): boolean {
    if (this.column) {
      if (col) {
        return this.column === col;
      } else {
        return this.column === 1;
      }
    }
    return true;
  }

  onAnswerChange($event) {
    this.change.emit($event);
  }

  getAnswer(param: string): string {
    return this.pathFinder.getAnswerValue(param, this.updatedAnswers);
  }

  getDataFromAnswers() {
    const mileageAnswer = this.getAnswer('Mileage');
    if (mileageAnswer) {
      this.mileage = parseInt(mileageAnswer, 10);
    }
    this.hpiFirstRegistered =  this.getAnswer('HPIFirstRegisteredDate');
    this.ancestryCategory = this.getAnswer('AncestryCategory');
  }
}
