<div class="form-group" [formGroup]="group" [hidden]="this.isHidden">
  <label class="question-label" [ngClass]="{'bold': boldLabel, 'more-padding' : largeButtons }" [attr.for]="identifier" *ngIf="label">{{ label }}</label>
  <label class="question-label d-block" [ngClass]="{'more-padding' : largeButtons }" *ngIf="explanation">{{ explanation }}</label>
  <div [attr.id]="identifier" class="btn-group-toggle" ngbRadioGroup>
    <label ngbButtonLabel class="btn list-button"
          *ngFor="let option of options; let i = index"
          [ngClass]="{ 
            'invalid': markInvalid,
            'btn-block': vertical,
            'vertical': vertical,
            'informed-answer': option.key === informedAnswer, 
            'default-answer': option.key === defaultAnswer, 
            'no-text' : getOptionIcon(option.value) !== '', 
            'more-options' : options.length > 4,
            'last' : i === options.length - 1,
            'active' : group.value.value === option.key,
            'readonly': readOnly,
            'small-two-line' : customClass === 'small-two-line',
            'extra-large' : largeButtons,
            'me-4': largeButtons
            }">
      <input formControlName="value" type="radio" [value]="option.key" (blur)="checkValid()" (focus)="removeInvalidMark()" (change)="valueChanged()" [disabled]="disabled || !isOptionEnabled(option.value)">
      <span *ngIf="optionIcon === ''" [innerHTML]="option.value" ></span>
      <img *ngIf="optionIcon !== ''" [ngClass]="optionIcon" alt="Option-Icon">
    </label>
  </div>
</div>
