import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';
import moment from 'moment';
import { EMPTY, Observable } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { DayTypes } from '../../enums/day-types';
import { ScrollTypes } from '../../enums/scroll-types';
import { AppointmentService } from '../../services/appointment.service';
import { NotificationService } from '../../services/notification.service';
import { CalendarHelper } from '../helpers/calendar-helper';
import { Appointment } from '../models/appointment.model';
import { BuyerCalendarSchedule } from '../models/buyer-calendar-schedule.model';
import { DayDetails } from '../models/day-details.model';
import { Site } from '../models/site.model';
import { BuyerSummary } from '../models/buyer-summary.model';

@Component({
  selector: 'app-day-view',
  templateUrl: './day-view.component.html',
  styleUrls: ['./day-view.component.scss'],
})

export class DayViewComponent implements AfterViewInit {

  constructor(private changeDetection: ChangeDetectorRef, private appointmentService: AppointmentService, private notifications: NotificationService) { }

  @Input() currentDate: number;
  @Input() currentTime: number;
  @Input() dayHours: number[];
  @Input() dayStartHour: number;
  @Input() dayEndHour: number;
  @Input() defaultDisplayStartHour: number;
  @Input() buyerWeeklySchedule: BuyerCalendarSchedule;
  @Input() isBranchView: boolean;

  @ViewChild('calendar') calendar: ElementRef;
  siteName: string;
  scrollTypes = ScrollTypes;
  calendarHeight: number;
  dayDetails: DayDetails;
  isNotInBranch: boolean;
  daysInThefuture = 0;
  dayId: number;
  siteId: number;
  site: Site;
  buyer: BuyerSummary;
  is7OrMoreDaysAhead: boolean;


  ngOnInit(): void {
    this.check();

    this.appointmentService.appointment.subscribe((appointment) => {
      const index = this.dayDetails.dailyAppointments.findIndex(obj =>
        obj.appointmentId === appointment.appointmentId
      );
      if (index !== -1) {
        this.dayDetails.dailyAppointments[index].assignedTo = appointment.assignedTo;
        this.changeDetection.detectChanges();
      }
    });
  }

  ngAfterViewInit() {
    this.calendarHeight = CalendarHelper.getCalendarHeight(this.calendar);
    CalendarHelper.setDefaultScrollPostion(
      this.defaultDisplayStartHour,
      this.dayStartHour,
      this.dayHours,
      this.calendarHeight
    );

    if (!this.isBranchView) {
      this.getBuyerWeeklySchedule();
    } else {
      this.getAppointmentsForSelectedSiteAndDate$(this.siteId)
        .pipe(take(1))
        .subscribe(() => { });
    }
    this.changeDetection.detectChanges();
  }

  getBuyerWeeklySchedule() {
    this.appointmentService
      .getBuyerWeeklySchedule(moment(this.currentDate).format('YYYY-MM-DD'))
      .pipe(
        tap((result) => {
          this.buyerWeeklySchedule.appointments = null;
          this.buyerWeeklySchedule = result;
          this.createDayAppointments();
          this.changeDetection.detectChanges();
        }),
        catchError(() => {
          return EMPTY;
        }),
        take(1)
      )
      .subscribe();
  }

  getAppointmentsForSelectedSiteAndDate$(siteId: number): Observable<Array<Appointment>> {
    return this.appointmentService
      .getAppointmentsForSiteAndDate$(siteId, moment(this.currentDate).format('YYYY-MM-DD'))
      .pipe(
        tap((result) => {
          if (result) {
            this.buyerWeeklySchedule.appointments = null;
            this.buyerWeeklySchedule.appointments = result;
            this.createDayAppointments();
            this.changeDetection.detectChanges();
          }
        }),
        catchError((err) => {
          this.notifications.dangerToast(
            `failed to get appointments for siteId ${siteId}, date ${this.currentDate}`,
            err
          );
          return EMPTY;
        })
      );
  }

  changeDate(scrollType: ScrollTypes) {
    this.dayDetails = null;

    const firstDate = moment(this.currentDate).day(1).format('YYYY-MM-DD');
    const lastDate = moment(this.currentDate).day(7).format('YYYY-MM-DD');

    if (scrollType === ScrollTypes.Next) {
      this.daysInThefuture++;
      this.currentDate = moment(this.currentDate).add(1, 'day').valueOf();
      if (moment(this.currentDate).day() === 1) {
        this.getBuyerWeeklySchedule();
      } else {
        this.createDayAppointments();
        this.changeDetection.detectChanges();
      }
    } else if (scrollType === ScrollTypes.Previous) {
      this.daysInThefuture--;
      this.currentDate = moment(this.currentDate).add(-1, 'day').valueOf();
      if (moment(this.currentDate).day() === 0) {
        this.getBuyerWeeklySchedule();
      } else {
        this.createDayAppointments();
        this.changeDetection.detectChanges();
      }
    } else {
      this.daysInThefuture = 0;
      this.currentDate = Date.now();
      const compareDate = moment(this.currentDate).format('YYYY-MM-DD');
      if (moment(compareDate).isBetween(firstDate, lastDate, undefined, '[]')) {
        this.createDayAppointments();
        this.changeDetection.detectChanges();
      } else {
        this.getBuyerWeeklySchedule();
      }
    }
  }

  createDayAppointments() {
    const appointments = new Array<Appointment>();
    this.buyerWeeklySchedule.appointments.forEach((x) =>
      appointments.push(new Appointment(x, this.buyerWeeklySchedule.buyer.durationInMinutes))
    );
    this.check();
    if (this.site || this.is7OrMoreDaysAhead) {
      this.isNotInBranch = false;
      this.siteName = this.is7OrMoreDaysAhead ? '' : this.site.name;
      const dailyAppointments = appointments.filter((x) => x.dayId === this.dayId);

      this.dayDetails = new DayDetails(
        this.dayId,
        moment(this.currentDate).day().toString(),
        moment(this.currentDate).format('dddd'),
        this.site,
        DayTypes.Current,
        this.buyer,
        dailyAppointments,
        this.calendarHeight,
        this.dayHours.length,
        this.currentDate
      );
    } else {
      this.siteName = 'Not in Branch';
      this.isNotInBranch = true;
    }
  }

  check() {
    this.dayId = moment(this.currentDate).day();
    this.siteId = this.appointmentService.getSiteId(this.buyerWeeklySchedule, this.dayId);
    this.site = this.buyerWeeklySchedule.sites.find((x) => x.siteId === this.siteId);
    this.buyer = this.buyerWeeklySchedule.buyer;
    this.is7OrMoreDaysAhead = this.daysInThefuture >= 7;
  }
}
