<div class="container-fluid main-page">
  <div class="row content">
    <div class="col-2 replace-padding" *ngIf="!isPostPurchase && isPurchaseMenuVisible">
      <div class="reg-plate">
          <app-registration-plate [vrm]="vrm" [isPurchaseMenu] ="true"></app-registration-plate>
      </div>
      <div class="row">
        <app-purchase-menu [quoteStateId]="quoteStateId" [currentState]="stateName" [vrm]="vrm" [canGoBack]="!isPurchaseFinalised"
          [parentBusy]="isBusy" (startRollback)="setBusy(true)" (stateReturn)="onGoBackInState($event)"></app-purchase-menu>
      </div>
      
    </div>
    <div class="mt-3 page-container" [ngClass]="isPostPurchase || !isPurchaseMenuVisible ? 'col-12' : 'col-10'">

      <div class="page-title sticky-top" [hidden]="!doShowStickyElements">

        <h2 class="page-header">{{ title }}</h2>

        <div [hidden]="!showNavigationButtons()" class="buttons">
          <button [hidden]="!appointmentValuationDetails" #appointmentDetailsButton type="button"
            class="appointment-details-btn nav-button me-2">
            Appointment Details
            <img class="receipt-img" src="../../assets/icons/buttons/recipt.png" alt="Receipt"/>
          </button>
          <button type="button" class="btn nav-button direction me-1" [disabled]="backButtonDisabled" (click)="onBackButton()">
            <img src="../../assets/icons/buttons/arrowBack.png" class="m-1" alt="Back">
            Back
          </button>
          <button #saveButton type="button" class="btn nav-button next direction me-2 save-button" [ngClass]="isFinishedLoading && isBusy ? 'loading' : ''"
            [disabled]="saveButtonDisabled">
            Next
            <img src="../../assets/icons/buttons/arrowNext.png" class="m-1" alt="Next">
          </button>
        </div>

        <div ngbDropdown [hidden]="!showNavigationButtons()" class="dropdown popout">
          <button ngbDropdownToggle id="dropdownMenuButton" class="popout-btn">
            <img src="../../assets/icons/buttons/popout.png" alt="Popout">
          </button>
          <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
            <button ngbDropdownItem #suspendButton type="button" class="float-btn popout-btn" [hidden]="!canEndPurchase"
              [ngClass]="isFinishedLoading && isBusy ? 'loading' : ''">
              <img src="../../assets/icons/buttons/suspend.png" alt="Suspend">
              Suspend
            </button>
            <button ngbDropdownItem #abortButton type="button" class="float-btn popout-btn quit-btn" [ngClass]="canEndPurchase ? 'not-top-btn' : ''" [disabled]="quitButtonDisabled">
              <img
                [src]="quitButtonDisabled ? '../../assets/icons/buttons/quit-disabled.png' : '../../assets/icons/buttons/quit.png'"
                alt="Not Purchased">
              Not Purchased
            </button>

            <button ngbDropdownItem [hidden]="stateName !== 'Start Purchase'" #notMyCarButton type="button"
              class="float-btn popout-btn not-top-btn">
              <img src="../../assets/icons/buttons/change_vrm.svg" alt="Not my car">
              Not My Car
            </button>
          </div>
        </div>
      </div>
      <div class="page-content">
        <div *ngIf="isFinishedLoading" class="row">
          <div *ngIf="!hasColumns" class="col-12">
            <app-question-column [questionForm]="questionForm" [quoteStateId]="quoteStateId" [vrm]="vrm"
              [questionPages]="questionPages" [stateName]="stateName" [isAppraisalFinalised]="isAppraisalFinalised"
              [mileage]="mileage" [vehicleDetails]="vehicleDetails" [updatedAnswers]="updatedAnswers"
              [questionDefaults]="questionDefaults" [requiredImageType]="requiredImageType" [answersSaved]="answersSaved"
              [saveNotifier]="saveNotifier" [isChangeVrmFlow]="isChangeVrmFlow" [isAltVRMFlow]="isAltVRMFlow"
              (change)="onAnswerChange($event)">
            </app-question-column>
          </div>
          <div *ngIf="hasColumns" class="col-6">
            <app-question-column [questionForm]="questionForm" [quoteStateId]="quoteStateId" [vrm]="vrm" [column]="1"
              [questionPages]="questionPages" [stateName]="stateName" [isAppraisalFinalised]="isAppraisalFinalised"
              [mileage]="mileage" [vehicleDetails]="vehicleDetails" [updatedAnswers]="updatedAnswers"
              [questionDefaults]="questionDefaults" [requiredImageType]="requiredImageType" [answersSaved]="answersSaved"
              [saveNotifier]="saveNotifier" [isChangeVrmFlow]="isChangeVrmFlow" [isAltVRMFlow]="isAltVRMFlow"
              (change)="onAnswerChange($event)">
            </app-question-column>
          </div>
          <div *ngIf="hasColumns" class="col-6" [ngClass]="state.secondColumnBottomAlign ? 'col-end' : ''">
            <app-question-column [questionForm]="questionForm" [quoteStateId]="quoteStateId" [vrm]="vrm" [column]="2"
              [questionPages]="questionPages" [stateName]="stateName" [isAppraisalFinalised]="isAppraisalFinalised"
              [mileage]="mileage" [vehicleDetails]="vehicleDetails" [updatedAnswers]="updatedAnswers"
              [questionDefaults]="questionDefaults" [requiredImageType]="requiredImageType" [answersSaved]="answersSaved"
              [saveNotifier]="saveNotifier" [isChangeVrmFlow]="isChangeVrmFlow" [isAltVRMFlow]="isAltVRMFlow"
              (change)="onAnswerChange($event)">
            </app-question-column>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<ng-container *ngIf="isBusy && !isFinishedLoading">
  <app-loading-wbac-animation></app-loading-wbac-animation>
</ng-container>

<ng-container *ngIf="loadingFromChild">
  <app-loading-wbac-animation></app-loading-wbac-animation>
</ng-container>
