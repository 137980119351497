import { Component, OnInit } from '@angular/core';
import { EMPTY } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { NotificationService } from '../services/notification.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VehicleListValue } from '../walk-in/models/vehicle-list-value.model';
import { GradeTypes } from '../enums/grade-types';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { DamageService } from '../services/damage.service';
import { Fault } from '../damage/models/fault.model';
import { ValuationService } from '../services/valuation.service';
import { QuickValuationLookupData } from './Models/quick-valuation-lookup-data.model';
import { ModalHelper } from '../helpers/modal-helper';
import { CustomValidators } from '../custom-validators/custom-validators';
import { ConfirmDialogService } from '../confirm-dialog/services/confirm-dialog.service';
import { SharedService } from '../core/shared.service';

@Component({
  selector: 'app-quick-quote',
  templateUrl: './quick-quote.component.html',
  styleUrls: ['./quick-quote.component.scss'],
})

export class QuickQuoteComponent implements OnInit {
  vrm: string;
  altVrm: string;
  isVRMFound = false;
  hasLookupError = false;
  vehicleDescription: VehicleListValue;
  multipleVehicleDescriptions: Array<VehicleListValue>;
  vehicleImage: string;
  defaultVehicleImageUrl: string;
  damageGrades: Array<Fault>;
  damageGradeComponentId = 853;
  grade1FaultId = 813;
  grade2FaultId = 814;
  grade3FaultId = 810;
  grade4FaultId = 811;
  grade5FaultId = 812;
  grades: (string | GradeTypes)[];
  selectedGrade: number;
  vehiclePriceOffered: number;
  showQuickQuoteValuation: boolean;
  isLoading = false;
  quickQuoteLoading = false;
  bookValuationLoading = false;
  quickQuoteForm: UntypedFormGroup;
  creatingNewValuation: boolean;
  mileage: number;
  bookingAppointment: boolean;
  unableToValue: boolean;
  hasSalvage: boolean;
  isAlt: boolean;
  hasMissingVrmRelationship: boolean;
  allowManualLookup: boolean;
  requiresManualValuation: boolean;

  constructor(
    private damageService: DamageService,
    private notifications: NotificationService,
    private valuationService: ValuationService,
    private modalService: NgbModal,
    private formBuilder: UntypedFormBuilder,
    private confirmDialogService: ConfirmDialogService,
    private sharedService: SharedService
  ) {
  }

  ngOnInit() {
    this.grades = Object.values(GradeTypes).filter(element => !isNaN(Number(element)));

    this.quickQuoteForm = this.formBuilder.group({
      mileage: ['', { validators: Validators.required, asyncValidators: [CustomValidators.validateNumber(1, 999999)] }],
      grade: ['', Validators.required]
    });

    this.getDamageGrades();
  }

  getQuickQuote() {
    this.quickQuoteLoading = true;
    const quickValuationLookupData = this.populateQuickValuationLookupData();
    this.valuationService
      .performQuickValuation$(quickValuationLookupData, false).pipe(
        tap(result => {
          if (result.requiresManualValuation) {
            this.hasLookupError = result.requiresManualValuation;
            this.requiresManualValuation = result.requiresManualValuation;
          } else {
            this.vehiclePriceOffered = result.vehiclePriceOffered;
            this.showQuickQuoteValuation = true;
          }
          this.quickQuoteLoading = false;
          ModalHelper.setModalPositionForCustomerQuestions();
        }),
        catchError(err => {
          this.notifications.dangerToast('Failed to get quick quote valuation', err);
          this.quickQuoteLoading = false;
          return EMPTY;
        }),
        take(1)
      ).subscribe();
  }

  populateQuickValuationLookupData(): QuickValuationLookupData {
    const selectedGradeFault = this.getGradeFault(this.selectedGrade);
    this.mileage = this.quickQuoteForm.get('mileage').value;
    const isManualLookup = this.sharedService.getIsManualLookup();
    const quickValuationLookupData = new QuickValuationLookupData(
      this.vehicleDescription.QuoteId,
      this.vehicleDescription.QuoteGuid,
      this.vrm,
      this.mileage,
      selectedGradeFault,
      isManualLookup,
      Number(this.vehicleDescription.CapCodeId)
    );

    return quickValuationLookupData;
  }

  getDamageGrades() {
    this.damageService.getDamageMatrix$().pipe(
      tap(result => {
        this.damageGrades = result.filter(x => x.componentId === this.damageGradeComponentId);
      }),
      catchError(err => {
        this.notifications.dangerToast('Failed to get grades from damage matrix', err);
        return EMPTY;
      }),
      take(1)
    ).subscribe();
  }

  getGradeFault(grade: number): Fault {
    let selectedGradeFault: Fault;

    // Use explicit fault IDs rather than trying to match on name
    // Not ideal using hard-coded faultIds, but the name changes
    switch(grade) {
      case 1: selectedGradeFault = this.damageGrades.find(x => x?.faultId === this.grade1FaultId); break;
      case 2: selectedGradeFault = this.damageGrades.find(x => x?.faultId === this.grade2FaultId); break;
      case 3: selectedGradeFault = this.damageGrades.find(x => x?.faultId === this.grade3FaultId); break;
      case 4: selectedGradeFault = this.damageGrades.find(x => x?.faultId === this.grade4FaultId); break;
      case 5: selectedGradeFault = this.damageGrades.find(x => x?.faultId === this.grade5FaultId); break;
    }

    return selectedGradeFault;
  }

  setGrade(grade: number) {
    this.selectedGrade = grade;
    this.quickQuoteForm.patchValue({
      grade: grade
    });
  }

  isSelectedGrade(grade) {
    return grade === this.selectedGrade;
  }

  tryAgain(isAlt: boolean) {
    this.isAlt = isAlt;
    if (!isAlt) {
      this.vrm = null;
    }
    this.hasLookupError = false;
    this.hasMissingVrmRelationship = false;
    this.unableToValue = false;
    this.isVRMFound = false;
    this.showQuickQuoteValuation = false;
    this.vehicleDescription = null;
    this.vehicleImage = null;
  }

  setVehicleDescriptions(vehicleDescriptions: Array<VehicleListValue>) {
    this.isVRMFound = vehicleDescriptions !== null;
    this.selectedGrade = null;
    this.quickQuoteForm.patchValue({
      grade: '',
      mileage: ''
    });
    if (vehicleDescriptions) {
      this.vehicleImage = vehicleDescriptions[0].ImageUrl;
      this.defaultVehicleImageUrl = vehicleDescriptions[0].DefaultVehicleImageUrl;
      this.hasSalvage = vehicleDescriptions[0].HasSalvage;
      if (vehicleDescriptions.length === 1) {
        this.vehicleDescription = vehicleDescriptions[0];
        this.vehicleDescription.Vrm = this.vrm;
      } else if (vehicleDescriptions.length > 1) {
        this.multipleVehicleDescriptions = vehicleDescriptions;
      }
    }
  }

  setSelectedDerivative(selectedDerivative: VehicleListValue) {
    this.multipleVehicleDescriptions = null;
    this.vehicleImage = selectedDerivative.ImageUrl;
    this.defaultVehicleImageUrl = selectedDerivative.DefaultVehicleImageUrl;
    this.vehicleDescription = selectedDerivative;
    this.vehicleDescription.Vrm = this.vrm;
    this.hasLookupError = false;
    this.hasMissingVrmRelationship = false;
  }

  setVrm(vrm: string) {
    this.vehicleDescription = null;
    if (this.isAlt) {
      this.altVrm = vrm;
    } else {
      this.vrm = vrm;
    }
  }

  manualLookup(allowManualLookup) {
    this.allowManualLookup = allowManualLookup;
    this.requiresManualValuation = allowManualLookup;
  }

  setHasLookupError(hasLookupError: boolean) {
    this.hasLookupError = hasLookupError;
  }

  setHasMissingVrmRelationship(hasMissingVrmRelationship: boolean) {
    this.hasMissingVrmRelationship = hasMissingVrmRelationship;
    this.hasLookupError = hasMissingVrmRelationship;
    this.isLoading = false;
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  bookValuation() {
    this.creatingNewValuation = true;
    this.bookValuationLoading = true;
  }

  setBookingAppointment(isBookingAppointment: boolean) {
    this.bookingAppointment = isBookingAppointment;
  }

  get bookId() {
    if (this.vehicleDescription?.CapCode?.endsWith('L')) {
      return 2;
    } else {
      return 1;
    }
  }

  // prevents input from adding characters on tablets after max limit is reached
  // (very specific scenario, might be caused by a bug)
  preventInputOnTablet(inputMileage: string) {
    const inputLength = inputMileage.length;
    const isMaxInput = inputLength >= 8;
    if (isMaxInput) {
        inputMileage = inputMileage.slice(0, -1);
        this.quickQuoteForm.patchValue({
          mileage: inputMileage
        });
      }
  }

  dismissModal() {
    this.modalService.dismissAll();
  }
}
