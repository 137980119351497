import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnDestroy,
} from '@angular/core';
import { catchError, take, tap, takeUntil } from 'rxjs/operators';
import { EMPTY, Subject } from 'rxjs';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { LookupService } from '../services/lookup.service';
import { NotificationService } from '../services/notification.service';
import { VehicleIdentification } from '../walk-in/models/vehicle-identification.model';
import { VehicleIdentifyProviderStatus } from '../walk-in/models/vehicle-identity-provider-status.model';
import { VehicleListValue } from '../walk-in/models/vehicle-list-value.model';
import { VehicleLookupStatusTypes } from '../enums/vehicle-lookup-status-types';
import { CustomValidators } from '../custom-validators/custom-validators';
import { ConfirmDialogService } from '../confirm-dialog/services/confirm-dialog.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthorizationRequestService } from '../services/authorization-request.service';
import { SharedService } from '../core/shared.service';
import { HpiRetryComponent } from '../vehicle-check-hpi/hpi-retry/hpi-retry.component';
import { Router } from '@angular/router';
import { HpiModalComponent } from '../hpi-modal/hpi-modal.component';
import { ToDoService } from '../services/to-do.service';
import { WalkInComponent } from '../walk-in/walk-in.component';
import { AuthorizationRequest } from '../authorization/models/authorization-request.model';
import { LoadingService } from '../services/loading.service';
import { DashMileageModalComponent } from '../dash-mileage-modal/dash-mileage-modal.component';
@Component({
  selector: 'app-search-vrm',
  templateUrl: './search-vrm.component.html',
  styleUrls: ['./search-vrm.component.scss'],
})
export class SearchVrmComponent implements OnInit, OnDestroy {
  @Input() displayVrm: string;
  @Input() vrmInput: string;
  @Input() loading: boolean;
  @Input() dataEntryOnly: boolean;
  @Input() quoteStateId: number;
  @Input() altVrm: boolean;
  @Input() isChangeVrm: boolean;
  @Input() isStartPurchase: boolean;
  @Input() isBookedVrm: boolean;
  @Output() vrm = new EventEmitter<string>();
  @Output() vehicleDescriptionsResult = new EventEmitter<
    Array<VehicleListValue>
  >();
  @Output() hasLookupError = new EventEmitter<boolean>();
  @Output() hasMissingVrmRelationship = new EventEmitter<boolean>();
  @Output() isloading = new EventEmitter<boolean>();
  @Output() isAltVrm = new EventEmitter<boolean>();
  @Output() allowManualLookup = new EventEmitter<boolean>();

  placeholder: string;
  isLookupError: boolean;
  isVehicleAlreadyPurchased: boolean;
  errorMessage: string;
  vehicleIdentification: VehicleIdentification;
  isVRMFound = false;
  vehicleDescriptions: Array<VehicleListValue>;
  vrmSearchForm: UntypedFormGroup;
  noInput = true;
  existingAuthorizationRequests: Array<AuthorizationRequest>;
  destroy$ = new Subject();

  constructor(
    private notifications: NotificationService,
    private lookupService: LookupService,
    private formBuilder: UntypedFormBuilder,
    private confirmDialogService: ConfirmDialogService,
    private modalService: NgbModal,
    private authorizationRequestService: AuthorizationRequestService,
    private sharedService: SharedService,
    private router: Router,
    private todoService: ToDoService,
    private loadingService: LoadingService
  ) { }

  ngOnInit() {
    this.vrmSearchForm = this.formBuilder.group({
      vrm: [
        this.isBookedVrm ? this.displayVrm : this.vrmInput,
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(8),
          Validators.pattern(CustomValidators.vrmRegex),
        ],
      ],
    });

    if (this.displayVrm && this.quoteStateId) {
      this.getAuthRequest();
    }
    this.placeholder = this.isChangeVrm
      ? 'Change VRM'
      : this.altVrm
        ? 'Enter Alt VRM'
        : 'Enter VRM';
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  getAuthRequest() {
    this.authorizationRequestService
      .getRequestByQuoteStateId$(this.quoteStateId)
      .pipe(
        tap(async (existingAuthorizationRequests) => {
          this.existingAuthorizationRequests = existingAuthorizationRequests;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  setupWalkin(vehicleIdentification: VehicleIdentification) {
    this.vehicleIdentification = vehicleIdentification;
    const hasAppointment =
      this.vehicleIdentification.appointmentStatus.hasAppointment;
    if (this.vehicleIdentification.vehicleRecentlyPurchased) {
      this.modalService.dismissAll();
      this.confirmDialogService
        .purchaseError(
          'We\'re unable to provide a quote at the moment.\n This vehicle may have been sold or purchased recently.'
        )
        .pipe(take(1))
        .subscribe();
    } else if (hasAppointment) {
      this.modalService.dismissAll();
      this.confirmDialogService
        .purchaseError(
          'Unable to create a new quote as there is already an appointment for this vehicle'
        )
        .pipe(take(1))
        .subscribe();
    } else {
      this.isVRMFound =
        this.vehicleIdentification.vehicleLabelValueLists !== null &&
        this.vehicleIdentification.vehicleLabelValueLists.length > 0;
      const lookupProviderStatus =
        this.vehicleIdentification.vehicleIdentifyProviderStatus;
      if (
        lookupProviderStatus &&
        (!this.isVRMFound ||
          lookupProviderStatus.statusId !== VehicleLookupStatusTypes.Success)
      ) {
        this.checkForLookupErrors(lookupProviderStatus);
      } else {
        this.sharedService.setIsManualLookup(false);
        this.populateVehicleDescription();
      }
    }
    if (this.altVrm && this.isVRMFound) {
      this.lookupService
        .vehicleCheckRelatedVrms$(
          this.displayVrm.toUpperCase(),
          this.vrmInput.toUpperCase(),
          this.quoteStateId ?? 0,
          true
        )
        .pipe(
          tap((vrmRelated) => {
            if (vrmRelated) {
              this.modalService.dismissAll();
              this.onSelectPlate();
            } else {
              this.checkVrmRelationship(vrmRelated);
            }
          }),
          takeUntil(this.destroy$)
        )
        .subscribe();
    } else {
      this.emitOutputValues();
    }

    if (vehicleIdentification.isManualLookup) {
      this.allowManualLookup.emit(vehicleIdentification.isManualLookup);
    }
  }

  searchVrm() {
    if (this.dataEntryOnly) {
      this.vrm.emit(this.vrmSearchForm.get('vrm').value);
    } else {
      this.isLookupError = false;
      this.loading = true;
      this.isloading.emit(this.loading);
      this.vrmInput = this.vrmSearchForm.get('vrm').value;
      this.lookupService
        .findVehicle$(this.vrmInput)
        .pipe(
          tap((result) => {
            this.setupWalkin(result);
          }),
          catchError((err) => {
            this.isLookupError = true;
            this.errorMessage =
              'Call to vehicle check failed. Please try again.\nIf the issue persists please contact IT support';
            this.notifications.dangerToast(
              'Call to vehicle check failed.',
              err
            );
            this.emitOutputValues();
            return EMPTY;
          }),
          take(1)
        )
        .subscribe();
    }
  }

  checkForLookupErrors(lookupProviderStatus: VehicleIdentifyProviderStatus) {
    const isProviderDown = lookupProviderStatus.providerDown;
    const statusId = lookupProviderStatus.statusId;
    if (
      lookupProviderStatus == null ||
      isProviderDown ||
      statusId === VehicleLookupStatusTypes.TimedOut ||
      statusId === VehicleLookupStatusTypes.Error
    ) {
      this.errorMessage =
        'It seems that the HPI data provider is temporarily down, please try again';

      const modalRef = this.modalService.open(HpiRetryComponent, {
        keyboard: false,
        backdrop: 'static',
        centered: true,
        size: 'lg',
      });
      modalRef.componentInstance.vrm = this.vrmInput;
      modalRef.componentInstance.appointmentId = 0;
      modalRef.componentInstance.thingToRetry$ =
        this.lookupService.findVehicle$(this.vrmInput);

      modalRef.componentInstance.hpiSuccess.subscribe((x) => {
        this.isLookupError = false;
        this.setupWalkin(x);
      });
    } else if (
      statusId === VehicleLookupStatusTypes.VehicleNotFound ||
      statusId === VehicleLookupStatusTypes.CapNotFound ||
      !this.isVRMFound
    ) {
      this.isLookupError = true;
    }
  }

  populateVehicleDescription() {
    this.vehicleDescriptions = new Array<VehicleListValue>();
    const hasSalvage = this.vehicleIdentification.hasSalvage;
    this.vehicleIdentification.vehicleLabelValueLists.forEach((vehicle) => {
      const description = new VehicleListValue(
        vehicle,
        this.vehicleIdentification.quoteId,
        this.vehicleIdentification.quoteGuid,
        '',
        hasSalvage
      );
      this.vehicleDescriptions.push(description);
    });
  }

  emitOutputValues() {
    this.loading = false;
    this.hasLookupError.emit(this.isLookupError);
    this.vrm.emit(this.vrmInput);
    if (this.vehicleDescriptions && this.vehicleDescriptions.length > 0) {
      this.vehicleDescriptionsResult.emit(this.vehicleDescriptions);
    }
    this.isloading.emit(this.loading);
  }

  allowOnlyAlphanumerics(inputVrm: string) {
    const inputLength = inputVrm.length;
    this.noInput = inputLength === 0;
    if (!this.noInput) {
      const isMaxInput = inputLength > 8;
      if (
        !CustomValidators.vrmRegex.test(inputVrm.charAt(inputLength - 1)) ||
        isMaxInput
      ) {
        inputVrm = inputVrm.slice(0, -1);
        this.vrmSearchForm.patchValue({
          vrm: inputVrm,
        });
      }
    }
  }

  checkVrmRelationship(vrmRelated: boolean) {
    if (vrmRelated) {
      this.populateVehicleDescription();
      this.isAltVrm.emit(true);
      this.emitOutputValues();
    } else {
      this.loading = false;
      this.hasMissingVrmRelationship.emit(true);
      this.isloading.emit(this.loading);
    }
  }

  changeVrmFlow(vrmRelated: boolean) {
    this.sharedService.isRelated.emit(vrmRelated);
    this.sharedService.vrm.emit(this.vrmInput);
    this.loading = false;
    this.isloading.emit(this.loading);
  }

  async onManualLookup(inputVrm: string) {
    await this.confirmDialogService.onManualLookup(this.quoteStateId, inputVrm);
  }

  onSelectPlate() {
    const modalRef = this.modalService.open(HpiModalComponent, {
      keyboard: false,
      backdrop: 'static',
      centered: true,
    });

    modalRef.componentInstance.altVrm = this.vrmInput.toUpperCase();
    modalRef.componentInstance.altVrmFlow = true;
    modalRef.componentInstance.title = 'Select VRM to continue';
    modalRef.componentInstance.message =
      'Please select which VRM is currently displayed on the vehicle in front on you';
    modalRef.componentInstance.vrm = this.displayVrm.toUpperCase();
    modalRef.componentInstance.quoteStateId = this.quoteStateId;
    modalRef.result.then(
      (data) => {
        this.loadingService.loading.emit(true);
        if (data.quoteStateId) {
          this.todoService
            .saveAnswerManually$(
              data.quoteStateId,
              data.isAppointmentVrm ? 'VehicleDetailsChanged' : 'AltVRM',
              'true',
              false
            )
            .pipe(
              tap(() => {
                this.todoService
                  .saveAnswerManually$(
                    data.quoteStateId,
                    'IsBookedVrm',
                    data.isAppointmentVrm ? 'true' : 'false',
                    false
                  )
                  .pipe(
                    tap(() => {
                      this.openDashMileageComponentModal(
                        data.appointmentVrm,
                        data.quoteStateId
                      );
                    })
                  )
                  .subscribe();
              }),
              catchError(() => {
                this.loadingService.loading.emit(false);
                return EMPTY;
              }),
              take(1)
            )
            .subscribe();
        } else {
          this.altVrm = false;
          this.lookupService
            .findVehicle$(data.altVrm)
            .pipe(
              tap((result) => {
                this.vehicleIdentification = result;
                this.populateVehicleDescription();
                const modalRef = this.redirectToModal(WalkInComponent);
                modalRef.componentInstance.isStartPurchase =
                  this.isStartPurchase;
                modalRef.componentInstance.isChangeVrm = false;
                modalRef.componentInstance.hasDescription = true;
                modalRef.componentInstance.isBookedVrm = data.isAppointmentVrm;
                modalRef.componentInstance.vrm = data.appointmentVrm;
                modalRef.componentInstance.altVrm = data.altVrm;
                modalRef.componentInstance.setVehicleDescriptions(
                  this.vehicleDescriptions
                );
                this.loadingService.loading.emit(false);
              }),
              catchError((err) => {
                this.isLookupError = true;
                this.errorMessage =
                  'Call to vehicle check failed. Please try again.\nIf the issue persists please contact IT support';
                this.notifications.dangerToast(
                  'Call to vehicle check failed.',
                  err
                );
                this.emitOutputValues();
                this.loadingService.loading.emit(false);
                return EMPTY;
              }),
              take(1)
            )
            .subscribe();
        }
      },
      () => { }
    );
  }

  redirectToModal(component): NgbModalRef {
    return this.modalService.open(component, {
      keyboard: false,
      backdrop: 'static',
      centered: true,
    });
  }

  openDashMileageComponentModal(appointmentVrm: string, quoteStateId: number) {
    const modalRef = this.modalService.open(DashMileageModalComponent, {
      keyboard: false,
      backdrop: 'static',
      centered: true,
      size: 'md',
    });
    modalRef.componentInstance.vrm = appointmentVrm;
    modalRef.componentInstance.quoteStateId = quoteStateId;
    modalRef.result.then(
      () => { },
      () => { }
    );
  }
}
