<div class="container-fluid main-page">
  <div class="top-nav-bar-container">
    <div class="top-nav-bar">
      <span class="title">{{ sitename }}</span>

      <div class="top-nav-btns">
        <button
          class="nav-button"
          [ngClass]="
            enableSaveReportBtn
              ? 'nav-button--enable_save_btn'
              : 'nav-button--disable_save_btn'
          "
          (click)="showSaveReportModal()"
        >
          Save Report
        </button>

        <div ngbDropdown class="dropdown popout">
          <button
            ngbDropdownToggle
            id="dropdownMenuButton"
            aria-haspopup="true"
            aria-expanded="false"
            class="popout-btn"
          >
            <img src="../../assets/icons/buttons/popout.png" alt="" />
          </button>
          <div
            ngbDropdownMenu
            class="dropdown-menu dropdown-menu-right"
            aria-labelledby="dropdownMenuButton"
          >
            <a ngbDropdownItem [routerLink]="" (click)="enableNonRunner()" class="dropdown-item popout-btn float-btn">
              <img
                src="../../assets/icons/stock/broken-car.png"
                alt="Non-Runner"
              />
              <span class="float-btn-text">Non-Runner</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="view-header-container">
    <div class="view-header">
      <div class="view-information-field">
        <div class="row height-100-percent row-header">
          <div
            class="tab daily"
            [ngClass]="isDailyStockView ? 'active' : 'inactive'"
            (click)="dailyStock()"
            clickable
          >
            <div class="daily-stock">
              <span
                [ngClass]="
                  isDailyStockView
                    ? 'daily-stock--header'
                    : 'daily-stock--header-weight'
                "
                >Daily Stock Check</span
              >
              <span class="daily-stock--completed" *ngIf="stockCheckCompleted"
                >This was completed by:</span
              >
              <span class="daily-stock--by" *ngIf="stockCheckCompleted"
                >{{ stockCheckCompletedBy }} {{ stockCheckCompleted | date : 'H:mm dd/MM/yy' }}</span
              >
            </div>
          </div>
          <div
            class="tab"
            [ngClass]="{
              'active': stockCheckCompleted && !isDailyStockView,
              'inactive': stockCheckCompleted && isDailyStockView,
              'locked': !stockCheckCompleted
            }"
            (click)="stockInventory()"
            clickable
          >
            <div class="stock-inventory">
              <img
                [hidden]="!noVehicleInStock && stockCheckCompleted"
                class="stock-inventory--image"
                src="../../assets/icons/stock/lock.png"
                alt=""
              />
              <span
                [ngClass]="
                  isDailyStockView
                    ? 'stock-inventory--header'
                    : 'stock-inventory--header-weight'
                "
                >Stock Inventory</span
              >
              <span class="stock-inventory--locked" [hidden]="!noVehicleInStock && stockCheckCompleted">
                This tab will remain locked until Daily Stock Check is completed
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="daily-stock-view" *ngIf="isDailyStockView">
    <app-daily-stock
      (saveReport)="enableReport($event)"
      (reportData)="reportData($event)"
      [isSaved]="isSavedToday"
      [stockCheckId]="stockCheckId"
    >
    </app-daily-stock>
  </div>
  <div class="daily-stock-view" *ngIf="!isDailyStockView">
    <app-stock-inventory
      (saveReport)="enableReport($event)"
      (reportData)="reportData($event)"
    ></app-stock-inventory>
  </div>
</div>
