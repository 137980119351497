import { Component, HostListener, Input, OnInit } from '@angular/core';
import { BaseInputComponent } from '../base-input/base-input.component';
import { EventsService } from '../../services/events.service';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Vin } from './vin';

@Component({
  selector: 'app-vin-input',
  templateUrl: './vin-input.component.html',
  styleUrls: ['../input.component.scss',
              './vin-input.component.scss'
  ]
})
export class VinInputComponent extends BaseInputComponent implements OnInit {

  @Input() questionForm: UntypedFormGroup;

  showWarning = false;
  warningMessage: string;
  warningBorder: boolean;

  vin: Vin;

  characters: UntypedFormGroup;

  constructor(private eventsService: EventsService) {
    super();
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent() {
    this.eventsService.valueChanged.emit(true);
  }

  ngOnInit() {
    super.ngOnInit();

    this.vin = new Vin(this.group.value.informedAnswer);

    this.group.controls.value.setValue(null); // Need to do this to override value from HPI default
    this.group.controls.value.addValidators([Validators.required, this.vinValidator(this.vin)])

    this.characters = new UntypedFormGroup({
      char1: new UntypedFormControl(this.getFormControl('VinChar1').controls.value.value),
      char2: new UntypedFormControl(this.getFormControl('VinChar2').controls.value.value),
      char3: new UntypedFormControl(this.getFormControl('VinChar3').controls.value.value),
      char4: new UntypedFormControl(this.getFormControl('VinChar4').controls.value.value),
    });

    // Rebuild group value from individual input values
    this.valueChanged();
  }

  getFormControl(parameterName: string): UntypedFormGroup {
    return this.questionForm.get('items')['controls'].filter(x => x.value.name === parameterName)[0];
  }

  valueChanged() {
    const input = 
      this.vin.start 
      + this.characters.controls.char1.value
      + this.characters.controls.char2.value
      + this.vin.middle
      + this.characters.controls.char3.value
      + this.characters.controls.char4.value;
    
    if (this.characters.controls.char1.value
        && this.characters.controls.char2.value
        && this.characters.controls.char3.value
        && this.characters.controls.char4.value
    ) {
      this.group.controls.value.setValue(input);
      this.setCharStatus();
      this.evaluateWarning();
    } else {
      this.group.controls.value.setValue(null);
      this.showWarning = false;
    }

    // Copy to controls linked to answers
    this.getFormControl('VinChar1').controls.value.setValue(this.characters.controls.char1.value);
    this.getFormControl('VinChar2').controls.value.setValue(this.characters.controls.char2.value);
    this.getFormControl('VinChar3').controls.value.setValue(this.characters.controls.char3.value);
    this.getFormControl('VinChar4').controls.value.setValue(this.characters.controls.char4.value);

    this.change.emit(this.group);
    this.eventsService.valueChanged.emit(true);
  }

  evaluateWarning() {
    const warning = this.group.controls.warning.value;

    let show = false;

    if (warning && this.group.value.value) {
      switch (warning.operator) {
        case 'not equals':
          show = warning.value.trim().toLowerCase() !== this.group.value.value.trim().toLowerCase();
          break;
        case 'equals':
          show = warning.value.trim().toLowerCase() === this.group.value.value.trim().toLowerCase();
          break;
        default:
          // No warning
      }
    }

    if (show) {
      this.showWarning = true;
      this.warningMessage = warning.message;
      this.warningBorder = warning.warningBorder;
    } else {
      this.showWarning = false;
      this.warningMessage = '';
      this.warningBorder = false;
    }
  }

  keypress($event) {
    if ($event.currentTarget.value.length > 1) {
      if ($event.key !== 'Delete' && $event.key !== 'Backspace') {
        $event.target.blur();
      }
    }
  }

  vinValidator(vin: Vin): ValidatorFn {
    return (control: AbstractControl) : ValidationErrors | null => {
      if (control.value && vin && vin.full != control.value) {
        this.showWarning = true;
        return {notMatched: true};
      } else {
        this.showWarning = false;
        return null;
      }
    }
  }

  setCharStatus() {
    if (this.vin.char1 != this.characters.controls.char1.value) {
      this.characters.controls.char1.setErrors({'incorrect]': true});
    }
    if (this.vin.char2 != this.characters.controls.char2.value) {
      this.characters.controls.char2.setErrors({'incorrect]': true});
    }
    if (this.vin.char3 != this.characters.controls.char3.value) {
      this.characters.controls.char3.setErrors({'incorrect]': true});
    }
    if (this.vin.char4 != this.characters.controls.char4.value) {
      this.characters.controls.char4.setErrors({'incorrect]': true});
    }
  }
}
