
export class Vin {
    full: string;

    // Parts for validation
    start: string;
    char1: string;
    char2: string;
    middle: string;
    char3: string;
    char4: string;

    constructor (fullVin: string) {
        this.full = fullVin;

        this.initializeParts();
    }

    private initializeParts() {
        if (this.full && this.full.length >= 6) {
            if (this.full.length > 6) {
                this.start = this.full.slice(0, this.full.length - 6)
            }
            this.char1 = this.full.slice(-6, -5);
            this.char2 = this.full.slice(-5, -4);
            this.middle = this.full.slice(-4, -2);
            this.char3 = this.full.slice(-2, -1);
            this.char4 = this.full.slice(-1);
        }
    }
}
