<form [formGroup]="valuationQuestionsForm" class="questionsForm mt-2">
    <div class="form-section mb-4" *ngIf="!isQuickQuote">
      <div class="form-label">Enter Vehicle Mileage</div>
      <input id="mileageInput" type="text" class="form-control input-text" placeholder="Enter Mileage"
             formControlName="mileage" pattern="^[0-9]+([0-9]{0,0})?$" (ngModelChange)="preventInputOnTablet($event)"
             maxlength="8" mask="separator" thousandSeparator=","
             required autocomplete="off" (click)="scrollModalToTopPosition(19)"/>
      <span class="placeholder">
        Enter Mileage
      </span>
      <span class="placeholder-line"></span>
    </div>
    <div class="form-section mb-4">
      <div class="form-label">Full Name</div>
      <input id="customerNameInput" type="text" class="form-control input-text input-text-default obfuscated-playback" placeholder="Enter Full Name"
             formControlName="customerName" required autocomplete="off" (click)="scrollModalToTopPosition(10)"/>
      <span class="placeholder">
        Enter Full Name
      </span>
      <span class="placeholder-line"></span>
    </div>
    <div class="form-section mb-4">
      <div class="form-label">Customer's Postcode</div>
      <input id="postcodeInput" type="text" class="form-control input-text input-text-upper postcode obfuscated-playback" placeholder="Enter Postcode"
             formControlName="postcode" required autocomplete="off" (click)="scrollModalToTopPosition(12)"/>
      <span class="placeholder">
        Enter Postcode
      </span>
      <span class="placeholder-line"></span>
    </div>
    <div class="form-section mb-4">
      <div class="form-label">Email Address</div>
      <input id="emailInput" type="text" class="form-control input-text input-text-lower obfuscated-playback" placeholder="Enter Email Address"
             formControlName="emailAddress" required autocomplete="off" (click)="scrollModalToTopPosition(20)"/>
      <span class="placeholder">
        Enter Email Address
      </span>
      <span class="placeholder-line"></span>
    </div>
    <div class="form-section mb-4">
      <div class="form-label">Mobile Number</div>
      <input id="phoneInput" type="text" class="form-control input-text input-text-default obfuscated-playback" placeholder="Enter Mobile Number"
             formControlName="telephone" required autocomplete="off" (click)="scrollModalToTopPosition(22)"/>
      <span class="placeholder">
        Enter Mobile Number
      </span>
      <span class="placeholder-line"></span>
    </div>
    <div class="form-section mb-4 single-line-question">
      <div class="form-label single-line-question-label">Would you like to receive marketing emails?</div>
      <div class="multiple-choice-btn-container">
        <a class="multiple-choice-btn yes-no-btn" [ngClass]="selectedMarketing === 0 ? 'selected-option' : ''" (click)="setMarketing(false)">No</a>
        <a class="multiple-choice-btn yes-no-btn last" [ngClass]="selectedMarketing === 1 ? 'selected-option' : ''" (click)="setMarketing(true)">Yes</a>
      </div>
    </div>
    <div class="form-section mb-4 single-line-question" *ngIf="canBeVatRegistered">
      <div class="form-label single-line-question-label">Are you VAT registered?</div>
      <div class="multiple-choice-btn-container">
        <a class="multiple-choice-btn yes-no-btn" [ngClass]="selectedVat === 0 ? 'selected-option' : ''" (click)="setVat(false)">No</a>
        <a class="multiple-choice-btn yes-no-btn last" [ngClass]="selectedVat === 1 ? 'selected-option' : ''" (click)="setVat(true)">Yes</a>
      </div>
    </div>
    <div class="walkin-valuation-btn">
      <button class="modal-button button_green" type="button" [ngClass]="{'active': valuationQuestionsForm.valid, 'loading': loading, 'disabled': !valuationQuestionsForm.valid}" [disabled]="!valuationQuestionsForm.valid || loading" (click)="startPurchase()">
        <span [ngClass]="{ 'loading': loading, 'disabled': !valuationQuestionsForm.valid }" class="next-btn-icon" ></span>
        <span class="next-btn-span">Next</span>
      </button>
    </div>
  </form>
