<div class="form-group" [formGroup]="altGroup" [hidden]="this.isHidden">
  <label class="question-label" [attr.for]="identifier" *ngIf="label">{{ label }}</label>
  <div class="input-group" ngbDropdown #theDropdown="ngbDropdown">
    <input readonly="readonly" class="form-control input-text dropDownInput" [attr.placeholder]="explanation" formControlName="list"
      autocomplete="off" autocorrect="off" ngbDropdownToggle />
      <span class="placeholder">{{ explanation }}</span>
      <span class="placeholder-line"></span>
      <img class="drop-down-img" src="../../../assets/icons/buttons/select-small-triangle-down.png" (click)="theDropdown.open();">
    <div ngbDropdownMenu class="pre-scrollable">
      <button (click)="valueChanged(option.key);theDropdown.close();" *ngFor="let option of options" id="{{option.key}}" ngbDropdownItem>{{ option.value }}</button>
    </div>
  </div>
</div>
