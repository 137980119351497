import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { SitesAndSlotsByPostcode } from '../book-appointment/models/sites-and-slots-by-postcode.model';
import { Appointment } from '../diary/models/appointment.model';
import { BuyerCalendarSchedule } from '../diary/models/buyer-calendar-schedule.model';
import { Site } from '../diary/models/site.model';
import { WalkInLookupData } from '../walk-in/models/walk-in-lookup-data.model';
import { AllocationInterval } from '../diary/models/allocation-interval.model';
import { SiteBuyerCount } from '../diary/models/site-buyer-count.model';

@Injectable({
  providedIn: 'root',
})

export class AppointmentService {
  constructor(private http: HttpClient) { }

  public appointment = new EventEmitter<Appointment>();

  getBuyerWeeklySchedule(date): Observable<BuyerCalendarSchedule> {
    return this.http.get<BuyerCalendarSchedule>(`${environment.ppxPrivateApi}api/Appointment/getBuyerWeeklySchedule?date=${date}`).pipe(
      catchError(err => throwError(err))
    );
  }

  bookNewAppointment$(walkInLookupData: WalkInLookupData): Observable<number> {
    return this.http.post<number>(`${environment.ppxPrivateApi}api/Appointment/bookNewAppointment`, walkInLookupData).pipe(
      catchError(err => throwError(err))
    );
  }

  bookNewAppointmentFromCancelledTask$(walkInLookupData: WalkInLookupData) {
    return this.http.post(`${environment.ppxPrivateApi}api/Appointment/bookNewAppointmentFromCancelledTask`, walkInLookupData).pipe(
      catchError(err => throwError(err))
    );
  }

  cancelAppointment$(appointmentId: number) {
    return this.http.get(`${environment.ppxPrivateApi}api/Appointment/cancelAppointment/${appointmentId}`).pipe(
      catchError((err) => throwError(err)));
  }

  getAppointmentSlots$(postcode: string, valuation: number, motExpiryDate: string, bookId: number): Observable<SitesAndSlotsByPostcode> {
    let url = '';
    if (postcode) {
      url = `${environment.ppxPrivateApi}api/Appointment/appointmentslots/${postcode}/${valuation}/${motExpiryDate}/${bookId}`;
    } else {
      url = `${environment.ppxPrivateApi}api/Appointment/appointmentslots/${valuation}/${motExpiryDate}/${bookId}`;
    }
    return this.http.get<SitesAndSlotsByPostcode>(url).pipe(
      catchError((err) => throwError(err)));
  }

  getAppointmentsForSiteAndDate$(siteId: number, date: string): Observable<Array<Appointment>> {
    return this.http.get<Array<Appointment>>(`${environment.ppxPrivateApi}api/Appointment/appointmentsForSiteAndDate/${siteId}/${date}`).pipe(
      catchError((err) => throwError(err)));
  }

  getSite$(siteId: number): Observable<Site> {
    return this.http.get<Site>(`${environment.ppxPrivateApi}api/Appointment/site/${siteId}`).pipe(
      catchError((err) => throwError(err)));
  }


  getBuyers$(siteId: number, date:string): Observable<SiteBuyerCount> {
    return this.http.get<SiteBuyerCount>(`${environment.ppxPrivateApi}api/Appointment/getBuyers/${siteId}/${date}`).pipe(
      catchError((err) => throwError(err)));
  }

  getSiteId(buyerSchedule: BuyerCalendarSchedule, dayId: number) {
    const timeNow = new Date();
    const timeText = timeNow.toTimeString().split(' ')[0];
    const hoursString = this.createDateObj(timeText);
    const entries = buyerSchedule.buyerWorkingIntervals.filter((x) => x.dayId === dayId);
    let siteId = entries.find(x => x.intervals.some(y => hoursString >= this.createDateObj(y.startTime.toString())
      && hoursString < this.createDateObj(y.endTime.toString())))?.siteId || null; // Get siteId if on work time
    if (siteId === null) {

      const intervals = new Array<AllocationInterval>();
      entries.forEach(element => {
        element.intervals.forEach(el => {
          intervals.push(el);
        });
      });

      if (intervals.length > 0) {
        const smallest = hoursString.getTime();
        let theInterval = new AllocationInterval;
        let startTime = intervals[0].startTime;
        intervals.forEach(element => {
          if (hoursString.getTime() >= this.createDateObj(element.startTime.toString()).getTime()) {
            if (hoursString.getTime() - this.createDateObj(element.startTime.toString()).getTime() < smallest) {
              theInterval = element;
            }
          }
          if (startTime > element.startTime) {
            startTime = element.startTime;
          }
        });

        if (timeNow < this.createDateObj(startTime.toString())) {
          siteId = entries.find(x => x.intervals.find(y => y.startTime === startTime)).siteId; // gets siteId if before work time
        } else {
          siteId = entries.find(x => x.intervals.find(y => y === theInterval))?.siteId; // Gets siteId if in a break or after work time
        }
      }
    }
    return siteId;
  }

  createDateObj(string) {
    const date = new Date();
    const [hours, minutes, seconds] = string.split(':');

    date.setHours(+hours);
    date.setMinutes(+minutes);
    date.setSeconds(+seconds);

    return date;
  }

  claimAppointment$(appointmentId: number, username: string): Observable<Appointment> {
    return this.http.post<Appointment>(`${environment.ppxPrivateApi}api/Appointment/claim/${appointmentId}/${username}`, null, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).pipe(
      catchError(err => throwError(err))
    );
  }
}
