<div hidden class="progress-container">
  <span class="progress-title">Service History</span>&nbsp;&nbsp;
  <i class="fa fa-circle progress-key-marker"></i>
  <span class="progress-key-text"> = Service history WBAC have established</span>
  <app-progress-bar [value]="percentComplete"></app-progress-bar>
</div>
<div class="service-records-title">
  Please enter the date and mileage of each service
</div>
<div [formGroup]="servicesFormGroup">
  <div formArrayName="services" class="services">
    <div class="service-record">
      <div class="header">
        <!-- Blank header -->
      </div>
      <div class="header date margin-right">
        Date
      </div>
      <div class="header">
        Mileage
      </div>
      <div class="header">
        Interval in months
      </div>
      <div class="header">
        Mileage interval
      </div>
      <div class="header">
        <!-- Blank header -->
      </div>
    </div>

    <ng-container *ngFor="let service of services.controls; let i = index;">

      <div class="service-record" [formGroupName]="i">
        <div class="row-number">
          {{ i + 1 }}
        </div>
        <div class="margin-right">
          <app-date-input formControlName="serviceDate" [minDate]="firstRegisteredOrManufacturedDate | date:'yyyy-MM-dd'"
            [maxDate]="today | date:'yyyy-MM-dd'"></app-date-input>
        </div>
        <div class="margin-right">
          <app-number-input formControlName="serviceMileage" explanation="Mileage">
          </app-number-input>
        </div>

        <div class="margin-right">
          <input readonly type="number" formControlName="monthInterval" class="form-control readonly-text" [ngClass]="{ 'interval-exceeded': isRowMonthsExceeded(i) }" tabindex="-1">
        </div>
        <div class="margin-right">
          <input readonly type="number" formControlName="mileageInterval" class="form-control readonly-text" [ngClass]="{ 'interval-exceeded': isRowMileageExceeded(i) }" tabindex="-1">
        </div>

        <div>
          <button class="remove-service-record" (click)="removeServiceRow(i)" [disabled]="services.length < 2"></button>
        </div>
      </div>
    </ng-container>

    <div class="add-service">
      <button class="btn list-button" type="button" [disabled]="servicesFormGroup.invalid" (click)="addServiceRow()">Add Service</button>
    </div>
    <div *ngIf="!isStillInWarranty" class="not-in-warranty">
      Due to the service record this vehicle is no longer in warranty
    </div>
  </div>
</div>

<div [hidden]="!showRetryButton">
  <button #retryButton type="button" class="btn btn-warning btn-block btn-lg my-1">
    Retry vehicle check
  </button>
</div>