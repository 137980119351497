<div class="form-group" [formGroup]="characters">
  <label class="question-label" [attr.for]="identifier">{{ label }}</label>
  <div class="vin-input-container">
    {{ vin.start}}
    <div class="input-group">
      <input #char1 formControlName="char1" class="form-control obfuscated-playback mystery-char" [appAutoTab]="char2"
        minlength="1" maxlength="1" oninput="this.value = this.value.toUpperCase()" placeholder="?"
        autocomplete="off" autocorrect="off" (change)="valueChanged()" (keydown)="keypress($event)" />
      <span class="placeholder-line"></span>
    </div>
    <div class="input-group me-2">
      <input #char2 formControlName="char2" class="form-control obfuscated-playback mystery-char" [appAutoTab]="char3"
        minlength="1" maxlength="1" oninput="this.value = this.value.toUpperCase()" placeholder="?"
        autocomplete="off" autocorrect="off" (change)="valueChanged()" (keydown)="keypress($event)" />
      <span class="placeholder-line"></span>
    </div>
    {{ vin.middle }}
    <div class="input-group">
      <input #char3 formControlName="char3" class="form-control obfuscated-playback mystery-char" [appAutoTab]="char4"
        minlength="1" maxlength="1" oninput="this.value = this.value.toUpperCase()" placeholder="?"
        autocomplete="off" autocorrect="off" (change)="valueChanged()" (keydown)="keypress($event)" />
      <span class="placeholder-line"></span>
    </div>
    <div class="input-group">
      <input #char4 formControlName="char4" class="form-control obfuscated-playback mystery-char"
        minlength="1" maxlength="1" oninput="this.value = this.value.toUpperCase()" placeholder="?"
        autocomplete="off" autocorrect="off" (change)="valueChanged()" (keydown)="keypress($event)" />
      <span class="placeholder-line"></span>
    </div>
  </div>
</div>
<div *ngIf="showWarning">
  <span class='warning-label'>{{ warningMessage }}</span>
</div>
<div [formGroup]="group" hidden>
  <input formControlName="value" />
</div>
