import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, UntypedFormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { BaseInputComponent } from '../base-input/base-input.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CustomValidators } from '../../custom-validators/custom-validators';

@Component({
  selector: 'app-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['../input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberInputComponent),
      multi: true,
    },
  ],
})
export class NumberInputComponent extends BaseInputComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() label: string;
  @Input() explanation: string;

  subscriptions = [];
  keyboardType: string;
  isPreFilledAnswer: boolean;
  destroy$ = new Subject();

  ngOnInit() {
    super.ngOnInit();
    if (this.group) {
      this.keyboardType = this.group.controls.keyboardType.value;
      const min = this.group.controls.minValue.value;
      const max = this.group.controls.maxValue.value;
      if (min || max) {
        this.group.controls.value.setAsyncValidators([CustomValidators.validateNumber(min, max)]);
      }
    } else {
      this.group = new UntypedFormGroup({
        value: new UntypedFormControl('', Validators.required),
      });
    }
    if (!this.keyboardType) {
      this.keyboardType = 'numeric';
    }
    if (this.group && this.group.value.value != null) {
      this.isPreFilledAnswer = true;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  valueChanged() {
    this.change.emit(this.group);
  }

  // ControlValueAccessor implementation

  writeValue(obj): void {
    this.group.controls.value.setValue(obj);
  }

  registerOnChange(fn): void {
      this.subscriptions.push(this.group.controls.value.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(fn));
  }

  onTouch = () => {};
  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
