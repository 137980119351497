import { BuyerSummary } from './buyer-summary.model';
import { Site } from '../models/site.model';
import { Appointment } from './appointment.model';
import { AllocationEntrySummary } from './allocation-entry-summary.model';

export class BuyerCalendarSchedule {
  sites: Array<Site> = [];
  buyer: BuyerSummary;
  appointments: Array<Appointment> = [];
  buyerWorkingIntervals: Array<AllocationEntrySummary> = [];
  buyersCount: number;
}

