import { AfterContentInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, UntypedFormGroup } from '@angular/forms';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { EMPTY, Subject, Subscription } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { BookAppointmentService } from '../book-appointment/services/book-appointment.service';
import { PostcodeLookupService } from '../postcode-lookup/services/postcode-lookup.service';
import { NotificationService } from '../services/notification.service';
import { PathFinderService } from '../services/path-finder.service';
import { DefaultAnswer } from '../user-input/models/default-answer.model';
import { WalkInLookupData } from '../walk-in/models/walk-in-lookup-data.model';
import { RequiredDocument } from './models/required-document.model';

@Component({
  selector: 'app-required-to-buy',
  templateUrl: './required-to-buy.component.html',
  styleUrls: ['./required-to-buy.component.scss']
})
export class RequiredToBuyComponent implements OnInit, AfterContentInit, OnDestroy {

  constructor(private pathFinder: PathFinderService, private notifications: NotificationService,
    private bookAppointmentService: BookAppointmentService, private postcodeLookupService: PostcodeLookupService) { }

  @Input() quoteStateId: number;
  @Input() vrm: string;
  @Input() group: UntypedFormGroup;
  @Input() questionForm: UntypedFormGroup;
  @Input() defaults: Array<DefaultAnswer>;

  requiredDocuments: Array<RequiredDocument>;
  purchaseForbidden: boolean;
  allowRebook: boolean;

  isRescheduling: boolean;
  destroy$ = new Subject();

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.requiredDocuments = new Array<RequiredDocument>();
    this.addStaticOptions();
    this.addDynamicOptions();
    this.addMissingDocsFromQuestions();
  }

  ngAfterContentInit() {
    setTimeout(() => {
      this.validate();
    });
  }

  addStaticOptions() {
    this.requiredDocuments.push(new RequiredDocument('Spare keys (if applicable)', null, true, false, 'MissingItems-Keys', null, '1'));
    this.requiredDocuments.push(new RequiredDocument('Transport home', null, true, false, null, null, null));
    this.requiredDocuments.push(new RequiredDocument('Any service records (if applicable)', null, true, false, null, null, null));
  }

  addDynamicOptions() {
    const hasFinance = this.defaults.find(x => x.parameterName === 'HasFinanceMarker');
    if (hasFinance && hasFinance.informedAnswer.toLowerCase() === 'true') {
      this.requiredDocuments.push(new RequiredDocument('Finance settlement letter', null, true, true, null, null, null));
    }
  }

  addMissingDocsFromQuestions() {
    this.pathFinder.getAnswers().forEach(element => {
      if (!element.isRolledBack && element.missingDocument) {
        this.requiredDocuments.push(new RequiredDocument(element.missingDocument, false, false, true, null, null, null));
      }
    });
  }

  toggle(doc: RequiredDocument, value: boolean) {
    if (doc.editable) {
      doc.present = value;
      if (doc.question) {
        const question = this.findFormGroupByParamName(doc.question);
        if (value) {
          question.get('value').setValue(doc.yesValue);
        } else {
          question.get('value').setValue(doc.noValue);
        }
      }
      this.validate();
    }
  }

  findFormGroupByParamName(name: string): UntypedFormGroup {
    const controlArray = this.questionForm.get('items') as FormArray;
    return controlArray.controls.find(
      (x) => (x as UntypedFormGroup).controls.name.value === name
    ) as UntypedFormGroup;
  }

  validate() {
    let canPurchase = true;
    let allAnswered = true;
    this.requiredDocuments.forEach(element => {
      if (element.present === null) {
        allAnswered = false;
      } else if (element.stopsPurchase && element.present === false) {
        canPurchase = false;
      }
    });
    this.purchaseForbidden = !canPurchase;
    this.allowRebook = this.purchaseForbidden && allAnswered;
    if (allAnswered && canPurchase) {
      this.group.controls.value.setValue(true);
    } else {
      this.group.controls.value.setValue(null);
    }
  }

  reschedule() {
    this.startBookingAppointment(null, false);
  }

  startBookingAppointment(postcode: string, isNewPostcodeLookup: boolean) {
    this.isRescheduling = true;

    const lookupData = new WalkInLookupData(0, '00000000-0000-0000-0000-000000000000', this.vrm,
      0, null, null, false, null, null, postcode, null, null);
    lookupData.quoteStateId = this.quoteStateId;

    const bookId = Number(this.pathFinder.getAnswerValue('BookId', null));
    const bookingAppointmentSubscription = this.bookAppointmentService.openBookAppointmentModal$(postcode, lookupData, bookId, true)
      .pipe(tap(result => {
        this.isRescheduling = false;
        if (isNewPostcodeLookup) {
          this.postcodeLookupService.setNewPostcodeSearchCompleted();
        }
        this.listenToBookAppointmentOutput(result, bookingAppointmentSubscription);
      }),
        catchError(err => {
          this.isRescheduling = false;
          this.notifications.dangerToast('Failed to start booking appointment', err);
          return EMPTY;
        }),
        takeUntil(this.destroy$)
      ).subscribe();
  }

  listenToBookAppointmentOutput(bookAppointmentModalRef: NgbModalRef, bookingAppointmentSubscription: Subscription) {
    bookAppointmentModalRef.componentInstance.backToValuationQuestions
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
      });

    bookAppointmentModalRef.componentInstance.requiresManualValuation
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
      });

    bookAppointmentModalRef.componentInstance.newPostcode
      .pipe(takeUntil(this.destroy$))
      .subscribe(postcode => {
        bookingAppointmentSubscription.unsubscribe();
        this.startBookingAppointment(postcode, true);
      });
  }
}
