<div class="form-group" [formGroup]="group" [hidden]="this.isHidden">
  <label class="question-label" [attr.for]="identifier" *ngIf="label">{{ label }}</label>
  <div class="input-group">
    <input [disabled]="this.disabled" [readOnly]="this.disabled" [attr.id]="identifier" class="form-control input-text obfuscated-playback" 
      [ngClass]="{ 'readonly-text' : disabled, 'prefilled-answer': isPreFilledAnswer }"
      [attr.inputmode]="keyboardType" (change)="valueChanged()" formControlName="value" [attr.placeholder]="explanation"
      autocomplete="off" autocorrect="off" mask="separator" thousandSeparator="," />
      <span class="placeholder">{{ explanation }}</span>
      <span class="placeholder-line"></span>
  </div>
</div>